import { Creative } from "@/interfaces/creative";
import { AssociationDataCreate } from "@/interfaces/creativeAssociation";
import { CampaignDataCreate, FrequencyCap } from "../interfaces/campaign";
import { SegmentDataCreate } from "@/interfaces/segment";
import { SegmentCategoryDataCreate } from "@/interfaces/segment_category";
import { RequestCampaignCreate, CreativeRequestCampaign } from "@/interfaces/request_campaign";
import { valuesVideoAdDuration } from "./fields";
import i18n from "@/plugins/i18n";

/**
 * Line Item Resources
 * @returns
 */
export function getLineItemResources() {
  return {
    campaigns: [],
    advertisers: [],
    line_item_types: [],
    creative_weighting_methods: [],
    budget_types: [],
    bid_strategies: [],
    strategies: [],
    strategies_filtered: [],
    bidding_shadings: [],
    campaigns_pacing: [],
    optimization_strategies: [],
    unit_times: [],
    timezones: [],
    line_pacings: [],
    selected_campaign: null,
    budget_display: "Total",
    budget_currency: undefined,
    pacing_behaviour: [],
    catchup_behaviour: [],
    types_frecuency_caps: [],
    type_frecuency_caps_selected: undefined,
    fields: {
      budget: {
        required: true,
        show: true,
        disabled: false,
      },
      budget_type_id: {
        required: true,
        show: true,
        disabled: false,
      },
			audio_type_id: {
				required: true,
				show: true,
				disabled: false,
			},
      fix_cpm: {
        required: false,
        show: false,
        disabled: false,
      },
      bid_shading_id: {
        required: false,
        show: false,
        disabled: false,
      },
      bid_strategy_id: {
        required: true,
        show: true,
        disabled: false,
      },
      strategy_id: {
        required: false,
        show: true,
        disabled: false,
      },
      line_pacing_id: {
        required: true,
        show: true,
        disabled: true,
      },
      daily_budget: {
        required: false,
        show: false,
        disabled: true,
      },
      pacing_behavior_id: {
        required: false,
        show: false,
        disabled: true,
      },
      catchup_behavior_id: {
        required: false,
        show: false,
        disabled: true,
      },
      multiplier_pacing_behavior: {
        required: false,
        show: false,
        disabled: true,
      },
      daily_budget_suggested: {
        required: false,
        show: false,
        disabled: true,
      },
      cpm_bid: {
        required: false,
        show: false,
        disabled: true,
      },
      max_cpm_bid: {
        required: false,
        show: false,
        disabled: true,
      },
      target_ecpm: {
        required: false,
        show: false,
        disabled: false,
      },
      target_ecpc: {
        required: false,
        show: false,
        disabled: true,
      },
      target_ctr: {
        required: false,
        show: false,
        disabled: false,
      },
      expected_ctr: {
        required: false,
        show: false,
        disabled: true,
      },
      target_ecpcv: {
        required: false,
        show: false,
        disabled: false,
      },
      target_cpcv: {
        required: false,
        show: false,
        disabled: false,
      },
      target_vcr: {
        required: false,
        show: false,
        disabled: false,
      },
    },
  };
}

export function initDataVariables() {
  return {
    app_site: {
      app_bundle_list: [],
      app_id_list: [],
      app_name: [],
      deal_id: [],
      deal_id_list: [],
      domain_list: [],
      placement_id: [],
      placement_list: [],
      publisher_id: [],
      publisher_id_list: [],
      site: [],
      site_list: [],
      app_name_attributes: getDataVariables().app_name,
      site_attributes: getDataVariables().site,
      placement_id_attributes: getDataVariables().site,
    },
    geo: {
      city: [],
      country: [],
      region: [],
      lat_long_list: [],
      location_type: [],
    },
    exchange: {
      inventory_source: [],
      auction_type: [],
    },
    environment: {
      environment_type: [],
      topframe: [],
      video_api: [],
      rewarded: [],
    },
    content: {
      content_category: [],
      language: [],
    },
    platform: {
      browser: [],
      browser_version: [],
      carrier: [],
      device_type: [],
      device_make: [],
      device_model: [],
      device_screen_size: [],
      operating_system: [],
      operating_system_version: [],
    },
    time: {
      user_time_of_week: [],
    },
    user: {
      segment: [],
    },
  };
}

export function getDataVariables() {
  return {
    app_name: [
      {
        value: "app_name",
        text: i18n.t("modifier.labels.appSite.app_name.by.app_name"),
      },
      {
        value: "app_id",
        text: i18n.t("modifier.labels.appSite.app_name.by.app_id"),
      },
      {
        value: "app_bundle",
        text: i18n.t("modifier.labels.appSite.app_name.by.app_bundle"),
      },
    ],
    site: [
      {
        value: "site_id",
        text: i18n.t("modifier.labels.appSite.site.by.site_id"),
      },
      {
        value: "site_name",
        text: i18n.t("modifier.labels.appSite.site.by.site_name"),
      },
      {
        value: "placement_id",
        text: i18n.t("modifier.labels.appSite.site.by.placement_id"),
      },
    ],
  };
}

/**
 * Creative
 * @returns
 */
export function initCreative() {
  return {
    budget_type_id: "",
		audio_type_id: "",
    alternative_id: "",
    creative_type_id: "",
    creative_template_id: "",
    name: "",
    start_date: "",
    end_date: "",
    secure: true,
    active: true,
    creative_advertiser: {
      advertiser_id: "",
      domain: "",
      category_id: "",
      app_bundle: "",
    },
    creative_ad_content: {
      primary_asset_id: "",
      secondary_asset_id: "",
      companion_size_id: "",
      companion_html: "",
      click_url: "",
      tag: "",
      creative_rule_id: "",
      video_duration: null,
			audio_duration: null,
    },
    creative_exchange_options: {
      vendors: [],

      landing_page_url: "",
      appnexus_submit: false,
      thumbnail_id: "",
    },
    creative_addon_settings: {
      addons: [],
      pixels: [],
      scripts: [],
      click_trackers: [],
      vast_events: [],
      progress_events: [],
    },
    creative_attributes: {
      size_id: "",
      width: "",
      height: "",
      mime_type_id: "",
      expandable_type_id: "",
      expandable_directions: [],
      video_mime_types: [],
      audio_mime_types: [],
      video_api_id: "",
      video_bit_rates: [],
			audio_bit_rates: [],
      responsive: false,
      in_banner_video_id: "",
      mraid_playable: false,
      moat_web_viewability: false,
      moat_inapp_viewability: false,
			skippable: false,
			skip_offset: '00:00:00',
    },
    line_associations: [],
  };
}

/**
 * Creative
 * @returns
 */
export function initResourcesCreative() {
  return {
    budget_type_id: getAssetsOptions(),
		audio_type_id: getAssetsOptionsAudio(),
    alternative_id: [],
    creative_type_id: [],
    creative_template_id: [],
    name: [],
    start_date: [],
    end_date: [],
    file: [],
    creative_size_id: [],
    assets_types: [],
    creative_advertiser: {
      advertiser_id: [],
      domain: [],
      category_id: [],
      app_bundle: [],
    },
    creative_ad_content: {
      primary_asset_id: [],
      secondary_asset_id: [],
      companion_size_id: [],
      companion_html: [],
      click_url: [],
      tag: [],
      creative_rule_id: [],
      video_duration: valuesVideoAdDuration(),
			audio_duration: valuesVideoAdDuration(),
    },
    creative_exchange_options: {
      vendors: [],
      landing_page_url: [],
      thumbnail_id: [],
    },
    creative_addon_settings: {
      addons: [],
      pixels: [],
      scripts: [],
      click_trackers: [],
      vast_events: [],
      progress_events: [],
    },
    creative_attributes: {
      size_id: [],
      width: [],
      height: [],
      mime_type_id: [],
      expandable_type_id: [],
      expandable_directions: [],
      video_mime_types: [],
      video_api_id: [],
      video_bit_rates: [],
			audio_bit_rates: [],
      in_banner_video_id: [],
      audio_mime_types: [],

    },
    line_associations: [],
    update_video_bit_rate: [],
  };
}

/**
 * Creative
 * @returns
 */
export function initRulesCreative() {
  return {
    id: [],
    budget_type_id: [],
		audio_type_id: [],
    alternative_id: [],
    creative_type_id: [],
    creative_template_id: [],
    name: [],
    start_date: [],
    end_date: [],
    file: [],
    creative_size_id: [],
    creative_advertiser: {
      advertiser_id: [],
      domain: [],
      category_id: [],
      app_bundle: [],
    },
    creative_ad_content: {
      title: [],
			click_url_audio: [],
      primary_asset_id: [],
      secondary_asset_id: [],
      secondary_size_id: [],
      companion_html: [],
      click_url: [],
      tag: [],
      creative_rule_id: [],
      video_duration: [],
			audio_duration: [],
    },
    creative_exchange_options: {
      vendors: [],
      landing_page_url: [],
      thumbnail_id: [],
    },
    creative_addon_settings: {
      addons: [],
      pixels: [],
      scripts: [],
      click_trackers: [],
      vast_events: [],
      progress_events: [],
    },
    creative_attributes: {
      size_id: [],
      width: [],
      height: [],
      mime_type_id: [],
      expandable_type_id: [],
      expandable_directions: [],
      video_mime_types: [],
			audio_mime_types: [],
      video_api_id: [],
      video_bit_rates: [],
			audio_bit_rates: [],
      in_banner_video_id: [],
			skippable: [],
			skip_time: [],

    },
    line_associations: [],
  };
}

/**
 * Creative Config
 * @returns
 */
export function createCreativeConfig() {
  return {
    customVideoDuration: false,
    tag_valido: "",
    tag_validated: false,
    snackbar: false,
    texto_snackbar: "",
    thumbnail_url_val: "",
    delete_all_performe: false,
  };
}

/**
 * Creative Message
 * @returns
 */
export function createCreativeMessage() {
  return {
    pixel: "",
    script: "",
    click_tracker: "",
    vast_event: "",
  };
}

/**
 * Campaign
 * @returns
 */
export function matchedPaths() {
  return {
    creative_template_id: "creative/creativeTemplates",
    creative_type_id: "creative/creativeTypes",
    creative_size_id: "creative/creativeSizes",
    advertiser_id: "advertiser/listCreativo",
    category_id: "creative/advertiserCategories",
    tag: "creative/getDetectAttributes",
    video_bit_rates: "creative/videoBidRate",
		audio_bit_rates: "creative/audioBidRate",
    audio_mime_types: "creative/audioTypes",
    companion_size_id: "creative/creativeSizes",
    assets_types: "creative/assetTypes",
    assets: "creative/assets",
    mime_type_id: "creative/mimeTypes",
    video_mime_types: "creative/mimeTypes",
    creative_rule_id: "creative/creativeRules",
    validate_tag: "creative/validateTag",
    expandable_type_id: "creative/expandableTypes",
    expandable_directions: "creative/expandableDirections",
    in_banner_video_id: "creative/inBannerVideos",
    video_api_id: "creative/videoApi",
    vendors: "creative/vendors",
    create_asset: "creative/creativeAssets",
    addons: "creative/addons",
    store: "creative/CreateNewCreative",
    update: "creative/UpdateCreative",
  };
}

/**
 * Campaign
 * @returns
 */
export function initCampaign(): CampaignDataCreate {
  return {
    start_date: "",
    end_date: "",
    continent: null,
    campaign_pmp: false,
    frequency_caps: [] as Array<FrequencyCap>,
  } as CampaignDataCreate;
}

/**
 * Targeting
 * @returns
 */
export function initTargeting(targeting_predicate_id: Number = 0): any {
  return {
    app_site: {
      app_bundle_list: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 1,
        targeting_predicate_id: targeting_predicate_id,
        unique: false,
      },
      app_id_list: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 2,
        targeting_predicate_id: targeting_predicate_id,
        unique: false,
      },
      app_name: {
        by_attribute: "app_name",
        value: [],
        targeting_terms: [],
        targeting_key_id: 3,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      deal_id: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 4,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      deal_id_list: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 5,
        targeting_predicate_id: targeting_predicate_id,
        unique: false,
      },
      domain_list: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 6,
        targeting_predicate_id: targeting_predicate_id,
        unique: false,
      },
      placement_id: {
        by_attribute: "placement_id",
        value: [],
        targeting_terms: [],
        targeting_key_id: undefined,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      placement_list: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 7,
        targeting_predicate_id: targeting_predicate_id,
        unique: false,
      },
      publisher_id: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 8,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      publisher_id_list: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 9,
        targeting_predicate_id: targeting_predicate_id,
        unique: false,
      },
      site: {
        by_attribute: "site_id",
        value: [],
        targeting_terms: [],
        targeting_key_id: 10,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      site_list: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 11,
        targeting_predicate_id: targeting_predicate_id,
        unique: false,
      },
    },
    geo: {
      country: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 12,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      city: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 13,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      lat_long_list: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 14,
        targeting_predicate_id: targeting_predicate_id,
        unique: false,
      },
      location_type: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 15,
        targeting_predicate_id: targeting_predicate_id,
        unique: false,
      },
      region: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 16,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
    },
    exchange: {
      auction_type: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 17,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      inventory_source: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 18,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
    },
    environment: {
      environment_type: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 19,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      topframe: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 20,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      video_api: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 21,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      rewarded: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 35,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
    },
    platform: {
      browser: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 22,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      browser_version: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 23,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      carrier: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 24,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      device_type: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 25,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      device_make: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 26,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      device_model: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 27,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      device_screen_size: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 28,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      operating_system: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 29,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      operating_system_version: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 30,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
    },
    content: {
      content_category: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 32,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
      language: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 33,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
    },
    time: {
      user_time_of_week: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 31,
        targeting_predicate_id: targeting_predicate_id,
        unique: true,
      },
    },
    user: {
      segment: {
        value: [],
        targeting_terms: [],
        targeting_key_id: 34,
        targeting_predicate_id: targeting_predicate_id,
        unique: false,
      },
    },
  };
}

export function getTargetingID(module_name: string | number, key_name: string | number): number | undefined {
  return getTargetingIDByValue()[module_name][key_name];
}

/**
 * Targeting keys
 * @returns
 */
export function getTargetingIDByValue() {
  return {
    // app_site
    app_site: {
      app_bundle_list: 1,
      app_id_list: 2,
      app_name: 3,
      deal_id: 4,
      deal_id_list: 5,
      domain_list: 6,
      placement_list: 7,
      publisher_id: 8,
      publisher_id_list: 9,
      site: 10,
      site_list: 11,
    },

    // geo
    geo: {
      country: 12,
      city: 13,
      lat_long_list: 14,
      location_type: 15,
      region: 16,
    },

    // exchange
    exchange: {
      auction_type: 17,
      inventory_source: 18,
    },

    // environment
    environment: {
      environment_type: 19,
      topframe: 20,
      video_api: 21,
      rewarded: 35,
    },

    // platform
    platform: {
      browser: 22,
      browser_version: 23,
      carrier: 24,
      device_type: 25,
      device_make: 26,
      device_model: 27,
      device_screen_size: 28,
      operating_system: 29,
      operating_system_version: 30,
    },

    // time
    time: {
      user_time_of_week: 31,
    },

    // content
    content: {
      content_category: 32,
      language: 33,
    },

    // user
    user: {
      segment: 34,
    },
  };
}

/**
 * Assets options
 * @returns
 */
export function getAssetsOptions() {
	return [
		{
			id: 1,
			value: "New",
		},
		{
			id: 2,
			value: "Existing",
		},
	];
}

/**
 * Assets options audio
 * @returns
 */
export function getAssetsOptionsAudio() {
	return [
		{
			id: 1,
			value: "Upload",
		},
		{
			id: 2,
			value: "Use Html",
		},
	];
}

/**
 * Line Item
 * @returns
 */
export function initLineItem(): any {
  return {
    id: null,
    advertiser_id: null,
    campaign_id: null,
    name: "",
    budget_type_id: null,
		audio_type_id: null,
    budget: null,
    daily_budget: null,
    daily_budget_suggested: null,
    start_date: "",
    end_date: "",
    line_duration: null,
    active: 0, // Active --> 1 | Inactive --> 0
    alternative_id: "",
    bid_strategy_id: null,
    strategy_id: null,
    line_pacing_id: null,
    line_item_type_id: null,
    bid_shading_id: null,
    creative_method_id: null,
    fix_cpm: null,
    cpm_bid: null,
    target_ecpm: null,
    target_ecpc: null,
    target_ctr: null,
    target_ecpcv: null,
    target_cpcv: null,
    target_vcr: null,
    max_cpm_bid: null,
    pacing_behavior_id: "",
    catchup_behavior_id: "",
    multiplier_pacing_behavior: "1.00",
    expected_ctr: null,
    frequency_caps: [] as Array<FrequencyCap>,
    creative_associations: [],
  } as any;
}

/**
 * Creative
 * @param creative
 * @returns
 */
export function initAssociationCreative(creative: Creative): AssociationDataCreate {
  return {
    creative_id: creative.id,
    line_item_id: undefined,
    start_date: undefined,
    end_date: undefined,
    active: true,
  } as AssociationDataCreate;
}

/**
 * Modifier
 * @returns
 */
export function initModifier() {
  return {
    id: undefined,
    external_id: undefined,
    name: undefined,
    advertiser_id: undefined,
    alternative_id: undefined,
    delivery_modifiers_option: 101,
    modifier_type_id: undefined,
    module_id: undefined,
    default_weight: undefined,
    active: true,
    terms: [],
    delivery_terms: [],
    fallback_weight: undefined,
    bid_model_id: undefined,
  };
}

/**
 * Modifier
 * @returns
 */
export function initModifierTerm(module_id?: Number) {
  return {
    module_name: undefined,
    module_id: module_id,
    key: undefined,
    value: undefined,
    modifier: undefined,
    matching_type_id: undefined,
    matching_type_name: undefined,
    value_name: undefined,
    override_multiplier_pacing_behavior: undefined,
  };
}

/**
 * Modifier
 * @returns
 */
export function initModifierTermDelivery(module_id?: Number) {
  return {
    targeting: [],
    weight: undefined,
    rank: undefined,
  };
}

/**
 * Modifier
 * @returns
 */
export function initModifierCampaignAssociation() {
  return {
    campaign_id: null,
    //line_item_id: null,
    modifier_id: null,
    max_bid: null,
    modifier_type: null,
    delivery_modifier_id: null,
  };
}
/**
 * Modifier
 * @returns
 */
export function initModifierLineAssociation() {
  return {
    //campaign_id: null,
    line_item_id: null,
    modifier_id: null,
    max_bid: null,
    modifier_type: null,
  };
}

/**
 * Segments
 * @returns
 *
 */
export function initSegments(): SegmentDataCreate {
  return {
    id: undefined,
    name: "",
    description: "",
    verb_usage_id: 604,
    days: 0,
    advertiser_id: null,
    categories: [],
  } as SegmentDataCreate;
}

export function initSegmentsCategory(): SegmentCategoryDataCreate {
  return {
    id: undefined,
    name: "",
    advertiser_id: null,
    active: 0,
    parent_id: null,
  } as SegmentCategoryDataCreate;
}

/**
 * Request Campaing
 * @returns
 *
 */
export function initRequestCampaign(): RequestCampaignCreate {
  return {
    id: undefined,
    type: null,
    type_id: null,
    owner_id: null,
    name: "",
    brand: "",
    currency: "",
    start_date: "",
    end_date: "",
    duration: "",
    buy_model_id: null,
    budget: null,
    kpi: null,
    mmp_id: null,
    mmp_traking_link: "",
    url_google_play: "",
    provider_id: null,
    status_id: null,
  } as RequestCampaignCreate;
}

/**
 * Creative Request Campaing
 * @returns
 *
 */
export function initCreativeRequestCampaign(): CreativeRequestCampaign {
  return {
    name: "",
    type_creative: undefined,
    size: [],
    title_text: "",
    body_text: "",
    call_to_action: "",
    aspect_ratio: [],
    imagen_display: [],
    imagen_native: [],
    imagen_video: [],
    icons: [],
    vid: [],
    fileIcon: undefined,
    fileImagen: undefined,
    fileVideo: undefined,
  } as CreativeRequestCampaign;
}

