import { SectionTabType } from "@/interfaces/report";

export class ReportSectionTab {
  id: number;
  key: SectionTabType;
  title: string;
  required: boolean;
  expanded: boolean;
  showFilter: boolean;
  showClearFilter: boolean;
  icon?: ReportSectionTabIcon;

  constructor(payload?: Partial<ReportSectionTab>) {
    this.id = payload?.id ?? 0;
    this.key = payload?.key ?? "report_type";
    this.title = payload?.title ?? "";
    this.required = payload?.required ?? false;
    this.expanded = payload?.expanded ?? false;
    this.showFilter = payload?.showFilter ?? false;
    this.showClearFilter = payload?.showClearFilter ?? false;
    this.icon = payload?.icon;
  }
}

export class ReportSectionTabIcon {
  filter?: ReportSectionTabIconValue;
  clearFilter?: ReportSectionTabIconValue;

  constructor(payload?: Partial<ReportSectionTabIcon>) {
    this.filter = payload?.filter;
    this.clearFilter = payload?.clearFilter;
  }
}

export class ReportSectionTabIconValue {
  value: string;
  label: string;

  constructor(payload?: Partial<ReportSectionTabIconValue>) {
    this.value = payload?.value || "";
    this.label = payload?.label || "";
  }
}
