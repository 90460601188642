import { isEmpty } from "lodash";

// @ts-ignore
import advertisers from "@/utils/json/advertiser";

// @ts-ignore
import campaign from "@/utils/json/campaign";

// @ts-ignore
import lineItem from "@/utils/json/lineItem";

// @ts-ignore
import creative from "@/utils/json/creative";

// @ts-ignore
import segment from "@/utils/json/segment";

// @ts-ignore
import customList from "@/utils/json/list";

// @ts-ignore
import modifier from "@/utils/json/modifier";

// @ts-ignore
import users from "@/utils/json/user";

// @ts-ignore
import privatepois from "@/utils/json/private";

// @ts-ignore
import requestCampaign from "@/utils/json/campaignRequest";

// @ts-ignore
import events from "@/utils/json/event.json";

// @ts-ignore
import lookalike from "@/utils/json/lookalike.json";

import { TabData, ViewField } from "@/interfaces/custom_view";
// @ts-ignore
import ActionsTable from "@/components/Header/Tables/ActionsTable.vue";
// @ts-ignore
import FilterGridAG from "@/components/Header/Tables/FilterGridAG.vue";
// @ts-ignore
import ActiveFieldTable from "@/components/Header/Tables/ActiveFieldTable.vue";
// @ts-ignore
import Archived from "@/components/Header/Tables/Archived/index.vue";
// @ts-ignore
import ThumbnailGrid from "@/components/Header/Tables/ThumbnailGrid.vue";
// @ts-ignore
import CustomizeRowGrid from "@/components/Header/Tables/CustomizeRowGrid.vue";
// @ts-ignore
import CustomizeRowObjectGrid from "@/components/Header/Tables/CustomizeRowObjectGrid.vue";

import i18n from "@/plugins/i18n";
import moment from "moment-timezone";
import { convLocaleString, formatPriceByCurrency } from "./convert";
import { AttributionType, isAttributionType } from "@/models/events/Tab";
import FieldEntity from "@/models/FieldEntity";
import { scheduler } from "timers/promises";

export const types = {
	porcentual: "%",
	pixel: "px",
};

/**
 * porcentual | pixel
 */
export const type: string = "pixel";

export const isPercentual: Boolean = type == "porcentual";

/**
 * Symbol
 * porcentual: %
 * pixel: px
 */
export const typeCalc: string = getType();

/**
 * Get type
 * @returns
 */
export function getType(): string {
	return types[type];
}

/**
 * Defaults
 */
export const defaultWidthColumns = {
	min: isPercentual ? 5 : 100,
	identifierMin: 70,
	identifierActiveMin: 125,
	identifierIDMin: 130,
	actionsMax: 90,
	defaultMin: isPercentual ? 10 : 150,
	defaultMax: isPercentual ? 40 : 250,
	max: isPercentual ? 100 : 400,
};

/**
 * Translate the JSONS for AG-GRID columns
 * @param items - A Object with all the jsons (The name of the key is the same as the translation)
 * @returns
 */
function translateFields(items: { [key: string]: any }): ViewField[] {
	let fields = Object.keys(items).map((key) => {
		let item = items[key];

		// Translate Metrics
		item?.metrics.map((metric) => {
			metric.value = `${key}.fields.${metric.key}` as string;

			return metric;
		});

		// Translate Dimensions
		item?.dimensions.map((dimension) => {
			dimension.value = `${key}.fields.${dimension.key}` as string;

			return dimension;
		});

		return item;
	});

	return fields;
}

export function customizeFields() {
	return translateFields({
		advertisers,
		campaign,
		lineItem,
		creative,
		segment,
		customList,
		modifier,
		users,
		requestCampaign,
		privatepois,
		events,
		lookalike
	}) as Array<FieldEntity>;
}

export function matchedRouteNames() {
	return {
		AdvertisersIndex: "advertiser",
		CampaignsIndex: "campaign",
		CreativesIndex: "creative",
		LineItemList: "line_item",
		SegmentsIndex: "segment",
		CustomListIndex: "custom_list",
		ModifiersIndex: "modifier",
		UsersIndex: "user",
		RequestCampaignIndex: "requestCampaign",
		PrivatePoisCreate: "privatepois",
		EventIndex: "events",
		LookalikeIndex: "lookalike",
	};
}

export async function findMatchedRouteNames(
	route_name: string
): Promise<string> {
	return matchedRouteNames()[route_name];
}

export async function findCustomizeFields(route_name: string) {
	return customizeFields().find((f) => f.route_name === route_name);
}

export async function prepareSelectedData(selected: any) {
	const values: Array<TabData> = Object.values(selected);

	let fields: Array<any> = [];

	values.forEach((v: TabData) => {
		fields = fields.concat(v.data);
	});

	return fields;
}

export function getHeaderColumn(config: {
	field: string;
	text: any;
	align?: string;
	sortable?: boolean;
	api_sortable?: boolean;
	filterable?: boolean;
}) {
	const result = {
		text: config.text,
		align: config?.align || "center",
		sortable: config?.sortable || false,
		api_sortable: config?.api_sortable || false,
		filterable: config?.filterable || true,
		value: config.field,
		width: getValueByType(config.field, false),
		reactive_width: getValueByType(config.field, true),
	};
	return result;
}

/**
 * Obtener los valores por tipo de {field}
 * @param field
 * @param isReactive // indica si True: se obtien un valor numerico concatenado al simbolo | False: si se obtiene un valor numerico
 * @returns
 */
export function getValueByType(field: string, isReactive: Boolean = false) {
	let value: number | string = `${defaultWidthColumns.defaultMin}`;

	if (["id", "external_id", "active"].includes(field)) {
		value = defaultWidthColumns.identifierMin;
	}

	if (field.includes("name")) {
		value = defaultWidthColumns.defaultMax;
	}

	if (field.includes("layer_name")) {
		value = defaultWidthColumns.defaultMax;
	}

	if (field.includes("actions")) {
		value = defaultWidthColumns.actionsMax;
	}

	if (!isReactive) {
		value = `${value}${getType()}`;
	}

	return value;
}

/**
 * Get Min | Max by type {field}
 * @param field
 * @param isMin
 * @returns
 */
export function getLimitByType(
	field: string,
	isMin: Boolean = false,
	isIdCheck: Boolean = false
) {
	if (!isMin) return defaultWidthColumns.max;

	let value: number = defaultWidthColumns.min;

	if (["id", "external_id"].includes(field)) {
		value = defaultWidthColumns.identifierMin;
	}

	if (["active", "appnexus_submit", "archived"].includes(field)) {
		value = defaultWidthColumns.identifierActiveMin;
	}

	if (field.includes("name")) {
		value = defaultWidthColumns.defaultMax;
	}

	if (field.includes("layer_name")) {
		value = defaultWidthColumns.defaultMax;
	}

	if (field.includes("actions")) {
		value = defaultWidthColumns.actionsMax;
	}

	if (isIdCheck && (field.includes("id") || field.includes("external_id"))) {
		value = defaultWidthColumns.identifierIDMin;
	}

	return value;
}

export async function prepareHeaders(
	fields: Array<string>,
	entity: string,
	columnsSelected?: Array<any>,
	isReset: Boolean = false
): Promise<Array<any>> {
	let headers: Array<any> = [];

	const hasExternalId: Boolean = has(fields, "external_id");

	fields.forEach((field) => {
		if (has(fields, field)) {
			if (!field) return;

			if (hasExternalId && field === "id") return;

			let header = columnsSelected?.find((c) => c.value == field);

			if (!header || isReset) {
				header = getHeaderColumn({
					field: field,
					//text: i18n.t(`${entity}.fields.${field}`),
					text: `${entity}.fields.${field}`,
				});
			}

			headers.push(header);
		}
	});
	/*
	const actions = getHeaderColumn({
		field: "actions",
		text: "",
	});

	headers.push(actions);*/

	return headers;
}

export function has(data: Array<string>, key: string) {
	return data.includes(key);
}

export async function prepareTableContent(
	fields: Array<string>,
	entities: Array<any>
): Promise<Array<any>> {
	let items: Array<any> = [];

	entities.forEach((entity) => {
		let item: any = {};

		// external_id
		item.external_id = entity?.external_id;

		/**
		 * Dimensions
		 */

		if (has(fields, "id")) {
			item.id = entity?.id;
		}

		if (has(fields, "key")) {
			item.key = entity?.key;
		}

		if (has(fields, "name")) {
			item.name = entity?.name;
		}

		if (has(fields, "last_name")) {
			item.last_name = entity?.last_name;
		}

		if (has(fields, "email")) {
			item.email = entity?.email;
		}

		if (has(fields, "position_name")) {
			if (entity?.position_name == null) {
				item.position_name = '';
			  } else {
				item.position_name = i18n.t(`users.positions.${entity.position_name}`);
			  }			
		}

		if (has(fields, "role_description")) {
			item.role_description = entity?.role_description;
		}


		if (has(fields, "advertiser_name")) {
			item.advertiser_name = entity?.advertiser?.name || entity?.advertiser_name;
		}

		// BEGIN EVENTS

		if (has(fields, "segment_id")) {
			item.segment_id = entity?.segment_id || "-";
		}

		if (has(fields, "segment_name")) {
			item.segment_name = entity?.segment_name || "-";
		}

		if(entity?.segment) {
			item.segment_name = entity?.segment?.name || "-";
			item.segment_id = entity?.segment?.external_id || "-";
		}

		if (has(fields, "account_id")) {
			item.account_id = entity?.account_id || "-";
		}

		if (has(fields, "event_attribution_id")) {
			item.event_attribution_id = entity?.event_attribution_id || "-";
		}

		if (has(fields, "event_type_id")) {
			item.event_type_id = entity?.event_type_id || "-";
		}

		if (has(fields, "value")) {
			item.value = entity?.value || "-";
		}

		if (has(fields, "event_attribution")) {
			// Procesamiento de eventAttribution
			const eventAttribution = entity?.event_attribution;
			const hasExtra = Boolean(eventAttribution?.extra);
			const itemDescription = hasExtra ? eventAttribution.description : eventAttribution;
			const itemValue = hasExtra ? eventAttribution.extra : eventAttribution;

			const attributionType = hasExtra
				? AttributionType.WHITELIST
				: AttributionType.WHITELIST_TEXT;

			// Asignación
			item.event_attribution = itemDescription;
			item.elegible = isAttributionType(attributionType, itemValue);
		}

		if (has(fields, "event_type")) {
			item.event_type = entity?.event_type?.description || entity?.event_type || "-";
		}

		if (has(fields, "campaigns")) {
			item.campaigns = entity?.campaigns?.length;
		}

		if (has(fields, "line_items")) {
			item.line_items = entity?.line_items?.length;
		}

		if (has(fields, "click_window_days")) {
			item.click_window_days = entity?.click_window_days || "-";
		}

		if (has(fields, "view_window_days")) {
			item.view_window_days = entity?.view_window_days || "-";
		}

		if (has(fields, "events_fired_today")) {
			item.events_fired_today = entity?.events_fired_today || "-";
		}

		if (has(fields, "events_fired_yesterday")) {
			item.events_fired_yesterday = entity?.events_fired_yesterday || "-";
		}

		if (has(fields, "events_fired_past_7_days_avg")) {
			item.events_fired_past_7_days_avg = entity?.events_fired_past_7_days_avg || "-";
		}

		if (has(fields, "events_fired_past_30_days_look_back")) {
			item.events_fired_past_30_days_look_back = entity?.events_fired_past_30_days_look_back || "-";
		}

		/**
		 * END EVENTS
		 */

		if (has(fields, "description")) {
			item.description = entity?.description;
		}

		if (has(fields, "campaign_name")) {
			item.campaign_name = entity?.campaign_name;
		}

		if (has(fields, "type_name")) {
			item.type_name = entity?.type_name;
			item.type_key = entity?.type_key;
		}

		if (has(fields, "type_description")) {
			item.type_description = entity?.type?.description;
		}

		if (has(fields, "line_item_type_name")) {
			item.line_item_type_name = entity?.line_item_type_name;
		}

		if (has(fields, "errors")) {
			item.errors = entity?.errors;
		}

		if (has(fields, "creative_size_name")) {
			item.creative_size_name = entity?.creative_size_name;
		}

		if (has(fields, "creative_type_name")) {
			item.creative_type_name = entity?.creative_type_name;
		}

		if (has(fields, "custom_list_items_count")) {
			item.custom_list_items_count = entity?.custom_list_items_count;
		}

		if (has(fields, "line_associations")) {
			item.line_associations = entity?.line_associations?.length;
		}

		if (has(fields, "campaigns_count")) {
			item.campaigns_count = entity?.campaigns_count;
		}

		if (has(fields, "creative_thumbnail_url")) {
			item.creative_thumbnail_url = entity?.creative_thumbnail_url;
		}

		if (has(fields, "advertiser_id")) {
			item.advertiser_id = entity?.advertiser_id;
		}

		if (has(fields, "appnexus_submit")) {
			item.appnexus_submit =
				entity?.creative_exchange_options?.appnexus_submit;
		}

		if (has(fields, "domain")) {
			item.domain = entity?.domain;
		}

		if (has(fields, "app_bundle")) {
			item.app_bundle = entity?.app_bundle;
		}

		if (has(fields, "currency")) {
			item.currency = entity?.currency.name;
		}

		if (has(fields, "category_id")) {
			item.category_id = entity?.category.name;
		}

		if (has(fields, "active")) {
			item.active = entity?.active;
		}

		if (has(fields, "archived")) {
			item.archived = Boolean(entity?.archived);
		}

		if (has(fields, "created_at")) {
			item.created_at = entity?.created_at
				? moment(entity?.created_at).format("YYYY-MM-DD HH:mm")
				: "";
		}

		if (has(fields, "updated_at")) {
			item.updated_at = entity?.updated_at
				? moment(entity?.updated_at).format("YYYY-MM-DD HH:mm:ss")
				: "";
		}

		if (has(fields, "start_date")) {
			item.start_date = entity?.start_date
				? moment(entity?.start_date).format("YYYY-MM-DD HH:mm")
				: "";
		}

		if (has(fields, "end_date")) {
			item.end_date = entity?.end_date
				? moment(entity?.end_date).format("YYYY-MM-DD HH:mm")
				: "";
		}

		if (has(fields, "last_access")) {
			item.last_access = entity?.last_access ? entity?.last_access : "";
		}

		if (has(fields, "budget")) {
			const num = entity?.budget || 0;
			item.budget = formatPriceByCurrency({amount:num,currency:entity.currency_key});
		}

		if (has(fields, "creative_associations_count")) {
			item.creative_associations_count =
				entity?.creative_associations_count;
		}

		if (has(fields, "line_items_count")) {
			item.line_items_count = entity?.line_items_count;
		}

		if (has(fields, "budget_remaining")) {
			const num = entity?.budget_remaining || 0;
			item.budget_remaining = formatPriceByCurrency({amount:num,currency:entity.currency_key});
		}

		if (has(fields, "bidding_status")) {
			item.bidding_status = entity?.bidding_status;
		}

		if (has(fields, "winning_status")) {
			item.winning_status = entity?.winning_status;
		}

		/**
		 * Metrics
		 */

		if (has(fields, "daily_budget")) {
			const num = entity?.daily_budget || 0;
			item.daily_budget = formatPriceByCurrency({amount:num,currency:entity.currency_key});
		}

		if (has(fields, "clicks")) {
			item.clicks = entity?.clicks || 0;
		}

		if (has(fields, "cpc")) {
			const num = entity?.cpc || 0;
			item.cpc = formatPriceByCurrency({amount:num,currency:entity.currency_key});
		}

		if (has(fields, "cpm")) {
			const num = entity?.cpm || 0;
			item.cpm = formatPriceByCurrency({amount:num,currency:entity.currency_key});
		}

		if (has(fields, "ctr")) {
			item.ctr = entity?.ctr || 0;
		}

		if (has(fields, "impression")) {
			item.impression = entity?.impression || 0;
		}

		if (has(fields, "spend")) {
			const num = entity?.spend || 0;
			item.spend = formatPriceByCurrency({amount:num,currency:entity.currency_key});
		}

		if (has(fields, "video_complete_percent")) {
			item.video_complete_percent = entity?.video_complete_percent || 0;
		}

		if (has(fields, "video_completes")) {
			item.video_completes = entity?.video_completes || 0;
		}

		if (has(fields, "user_count_nam")) {
			item.user_count_nam = entity?.user_count_nam || 0;
		}

		if (has(fields, "user_count_emea")) {
			item.user_count_emea = entity?.user_count_emea || 0;
		}

		if (has(fields, "user_count_apac")) {
			item.user_count_apac = entity?.user_count_apac || 0;
		}

		if (has(fields, "campaign_type")) {
			item.campaign_type = entity?.campaign_type || 0;
		}

		if (has(fields, "kpi")) {
			item.kpi = entity?.kpi || 0;
		}

		if (has(fields, "brand")) {
			item.brand = entity?.brand || 0;
		}

		if (has(fields, "country_name")) {
			item.country_name = entity?.country_name;
		}

		if (has(fields, "layer_name")) {
			item.layer_name = entity?.layer_name;
		}

		if (has(fields, "total_pois")) {
			item.total_pois = entity?.total_pois;
		}

		if (has(fields, "total_errors")) {
			item.total_errors = entity?.total_errors;
		}

		if (has(fields, "status_name")) {
			item.status_name = entity?.status_name;
		}

		if (has(fields, "report_status")) {
			item.report_status = i18n.t(`myReport.index.status.${entity?.report_status}`);
		}
		
		/** Lookalike */
		if (has(fields, "campaign_id")) {
			item.campaign_id = entity?.campaign_id;
		}

		if (has(fields, "campaign_name")) {
			item.campaign_name = entity?.campaign_name;
		}

		if (has(fields, "country_name")) {
			item.country_name = entity?.country_name;
		}

		if (has(fields, "segment_name")) {
			item.segment_name = entity?.segment_name;
		}

		if (has(fields, "segment_size")) {
			item.segment_size = entity?.segment_size;
		}

		if (has(fields, "segment_total_users")) {
			item.segment_total_users = entity?.segment_total_users;
		}

		items.push(item);
	});

	return items;
}

export async function prepareTableData(params: {
	fields: Array<string>;
	entity: string;
	entities: Array<any>;
	columns?: Array<any>;
	isReset?: Boolean;
}) {
	const columnsSelected: Array<any> = await getColumnsSelected(params);

	let headers: Array<any> = await prepareHeaders(
		params.fields,
		params.entity,
		columnsSelected,
		params.isReset
	);

	return {
		headers: headers,
		items: await prepareTableContent(params.fields, params.entities),
	};
}

export async function getColumnsSelected(params: {
	fields: Array<string>;
	columns?: Array<any>;
}) {
	return (
		params.columns?.filter((pc) => params.fields.includes(pc.value)) ?? []
	);
}

export function preparedColumnDefsGrid(header: Array<any>, config: any) {
	if (isEmpty(header)) {
		return [];
	} else {
		let columnDefs = new Array();
		let entityName = config.entityName;
		let entityLinks = ["Campaign", "Line Item", "Event"];
		let entityLinkSeg = ["Event"];
		// Add here every title key you want to has the wrap property
		const WRAP_TITLES = [
			"line_associations",
			"appnexus_submit",
			"campaigns_count",
			"video_completes",
			"video_complete_percent",
			"line_items_count",
			"budget_remaining",
			"creative_associations_count",
			"line_item_type_name",
			"bidding_status",
			"winning_status",
			"campaign_type",
			"custom_list_items_count",
			"user_count_nam",
			"user_count_emea",
			"user_count_apac",
			"advertiser_name",
			"campaign_name",
			"app_bundle",
			"upload_status",
			"create_date",
			"upload_message",
			"upload_complete_date",
			"status_name"
		];

		header.forEach((h) => {
			const valueText = i18n.t(h.text) as string;
			const hValue = h.value as string;

			let isWrappable = WRAP_TITLES.includes(h.value);
			let minWidth = 0;

			if (isWrappable) {
				valueText.split(" ").forEach((text) => {
					const length = text.length * 11;
					if (length > minWidth) {
						minWidth = length;
					}
				});
				minWidth += 70;
			} else {
				minWidth = valueText.length * 11 + 70;
			}

			let obj = {
				headerName: valueText,
				field: hValue,
				width: Number(h.reactive_width),
				minWidth,
				//maxWidth: getLimitByType(hValue),
				flex: config.hasOwnProperty("flex") ? config.flex : 0,
				filter: "FilterGridAG",
				filterFramework: FilterGridAG,
				filterParams: {
					filters: config.hasOwnProperty("filters")
						? config.filters
						: null,
					options: config.hasOwnProperty("options")
						? config.options
						: null,
				},
			};

			if (
				[
					String("id").toUpperCase(),
					String("external_id").toUpperCase(),
				].includes(String(hValue).toUpperCase())
			) {
				obj["pinned"] = "left";
				obj["maxWidth"] = 150;
				obj["resizable"] = config.hasOwnProperty("resizableID")
					? config.resizableID
					: false;
				obj["lockPosition"] = "left";
				obj["headerCheckboxSelection"] = config.hasOwnProperty(
					"headerCheckboxSelection"
				)
					? config.headerCheckboxSelection
					: false;
				obj["checkboxSelection"] = config.hasOwnProperty(
					"checkboxSelection"
				)
					? config.checkboxSelection
					: false;
				if (config.hasOwnProperty("checkboxSelection")) {
					obj["minWidth"] = getLimitByType(hValue, true, true);
				}
			}
			if (String(hValue).toUpperCase() == String("country_name").toUpperCase()) {
				obj["minWidth"] = 200;
				obj["maxWidth"] = 800;
				obj["cellClass"] = "ag-padding-cell";
			}
			if (String(hValue).toUpperCase() == String("filters").toUpperCase()) {
				obj["minWidth"] = 400;
				obj["maxWidth"] = 800;
				obj["cellClass"] = "ag-padding-cell";
			}
			if (String(hValue).toUpperCase() == String("name").toUpperCase()) {
				obj['minWidth'] = 200;
				obj["maxWidth"] = 1000;
				obj['cellClass'] = 'ag-padding-cell';
			};
			if (String(hValue).toUpperCase() == String("campaign_name").toUpperCase()) {
				obj['cellClass'] = 'ag-padding-cell';
				obj["maxWidth"] = 800;
				if(!isEmpty(entityName) && entityLinks.includes(entityName)){
					obj['cellRenderer'] = "CustomizeRowGrid";
					obj['cellRendererFramework'] = CustomizeRowGrid;
					obj['cellRendererParams'] = {
						link: true,
						prefix: "",
						suffix: "",
						redirecCustom: { isInvoke: true },
						fieldText: hValue,
						contextT: config.hasOwnProperty("context") ? config.context : null,
					};
				}
			};
			if (String(hValue).toUpperCase() == String("name").toUpperCase()) {
				obj['cellClass'] = 'ag-padding-cell';
				obj["maxWidth"] = 1000;
				if(!isEmpty(entityName) && entityLinks.includes(entityName)){
					obj['cellRenderer'] = "CustomizeRowGrid";
					obj['cellRendererFramework'] = CustomizeRowGrid;
					obj['cellRendererParams'] = {
						link: true,
						prefix: "",
						suffix: "",
						redirecCustom: config.hasOwnProperty("redirecCustom") ? config.redirecCustom : {},
						fieldText: hValue
					};
				}
			};
			if (String(hValue).toUpperCase() == String("advertiser_name").toUpperCase()) {
				obj['cellClass'] = 'ag-padding-cell';
				obj["maxWidth"] = 800;
				obj["minWidth"] = 200;
				if(!isEmpty(entityName) && entityLinks.includes(entityName)){
					obj['cellRenderer'] = "CustomizeRowGrid";
					obj['cellRendererFramework'] = CustomizeRowGrid;
					obj['cellRendererParams'] = {
						link: true,
						prefix: "",
						suffix: "",
						redirecCustom: { isInvoke: true },
						fieldText: hValue,
						contextT: config.hasOwnProperty("context") ? config.context : null,
					};
				}
			};
			if (String(hValue).toUpperCase() == String("segment_name").toUpperCase()) {
				obj['cellClass'] = 'ag-padding-cell';
				if(!isEmpty(entityName) && entityLinkSeg.includes(entityName)){
					obj['cellRenderer'] = "CustomizeRowGrid";
					obj['cellRendererFramework'] = CustomizeRowGrid;
					obj['cellRendererParams'] = {
						link: true,
						prefix: "",
						suffix: "",
						redirecCustom: { isInvoke: true },
						fieldText: hValue,
						contextT: config.hasOwnProperty("context") ? config.context : null,
					};
				}
			};
			if (String(hValue).toUpperCase() == String("start_date").toUpperCase()) {
				obj['minWidth'] = 180
				obj["maxWidth"] = 800;
			};
			if (String(hValue).toUpperCase() == String("end_date").toUpperCase()) {
				obj['minWidth'] = 180
				obj["maxWidth"] = 800;
			};
			if (String(hValue).toUpperCase() == String("active").toUpperCase()) {
				obj['cellRenderer'] = "ActiveFieldTable";
				obj['cellRendererFramework'] = ActiveFieldTable;
				obj['cellRendererParams'] = {
					contextT: config.hasOwnProperty("context") ? config.context : null,
					eventActive: config.hasOwnProperty("eventActive") ? config.eventActive : false,}
				obj["minWidth"] = 200;
				obj["maxWidth"] = undefined;
				obj["cellClass"] = "ag-padding-cell";
			}
			if (
				String(hValue).toUpperCase() ==
				String("campaign_name").toUpperCase()
			) {
				obj["cellClass"] = "ag-padding-cell";
				obj["maxWidth"] = 800;
			}
			if (
				String(hValue).toUpperCase() ==
				String("advertiser_name").toUpperCase()
			) {
				obj["cellClass"] = "ag-padding-cell";
				obj["minWidth"] = 200;
				obj["maxWidth"] = 800;
			}
			if (
				String(hValue).toUpperCase() ==
				String("start_date").toUpperCase()
			) {
				obj["maxWidth"] = 800;
				obj["minWidth"] = 180;
			}
			if (
				String(hValue).toUpperCase() == String("end_date").toUpperCase()
			) {
				obj["maxWidth"] = 800;
				obj["minWidth"] = 180;
			}
			if (
				String(hValue).toUpperCase() == String("active").toUpperCase()
			) {
				obj["cellRenderer"] = "ActiveFieldTable";
				obj["cellRendererFramework"] = ActiveFieldTable;
				obj["cellRendererParams"] = {
					contextT: config.hasOwnProperty("context")
						? config.context
						: null,
					eventActive: config.hasOwnProperty("eventActive")
						? config.eventActive
						: false,
					field: "active",
					readonly: config.hasOwnProperty("readonly")
						? config.readonly
						: false,
					entityName: config.hasOwnProperty("entityName") 
						? config.entityName 
						: "",
					permission: config.hasOwnProperty("permission") 
						? config.permission 
						: false
				};
			}
			if (
				String(hValue).toUpperCase() === String("archived").toUpperCase()
			) {
				obj["cellRenderer"] = "Archived";
				obj["cellRendererFramework"] = Archived;
				obj["cellRendererParams"] = {
					contextT: config.hasOwnProperty("context")
						? config.context
						: null,
					showSwitch: config.hasOwnProperty("showSwitch")
						? !!config.showSwitch
						: false,
					field: "archived",
					readonly: config.hasOwnProperty("readonly")
						? config.readonly
						: false,
					entityName: config.hasOwnProperty("entityName") 
						? config.entityName 
						: "",
					permission: config.hasOwnProperty("permission") 
						? config.permission 
						: false
				};
			}
			/*
			if(String(h.text).toUpperCase() == String("status").toUpperCase()){
				obj['cellRenderer'] = "ActiveFieldTable";
				obj['cellRendererFramework'] = ActiveFieldTable;
				obj['cellRendererParams'] = {
					contextT: config.hasOwnProperty("context") ? config.context : null,
					eventActive: config.hasOwnProperty("eventActive") ? config.eventActive : false,
					flagText: config.hasOwnProperty("flagText") ? config.flagText : false,
					field: "status"
				};
			};*/
			if (
				String(hValue).toUpperCase() ==
				String("appnexus_submit").toUpperCase()
			) {
				obj["cellRenderer"] = "ActiveFieldTable";
				obj["cellRendererFramework"] = ActiveFieldTable;
				obj["cellRendererParams"] = {
					contextT: config.hasOwnProperty("context")
						? config.context
						: null,
					eventActive: config.hasOwnProperty("appNexus")
						? config.appNexus
						: false,
					field: "appnexus",
					readonly: config.hasOwnProperty("readonly")
						? config.readonly
						: false,
					entityName: config.hasOwnProperty("entityName") 
						? config.entityName 
						: "",
					permission: config.hasOwnProperty("permission") 
						? config.permission 
						: false
				};
			}
			if (
				String(hValue).toUpperCase() ==
				String("creative_thumbnail_url").toUpperCase()
			) {
				obj["cellRenderer"] = "ThumbnailGrid";
				obj["cellRendererFramework"] = ThumbnailGrid;
				obj["maxWidth"] = 800;
			}
			if (
				String(hValue).toUpperCase() ==
				String("campaigns_count").toUpperCase()
			) {
				obj["cellRenderer"] = "CustomizeRowGrid";
				obj["cellRendererFramework"] = CustomizeRowGrid;
				obj["cellRendererParams"] = {
					link: true,
					prefix: "",
					suffix: "",
					redirecCustom: {
						to: "CampaignsIndex",
						entity: "advertiser_id",
					},
					fieldText: hValue,
					permission: config.hasOwnProperty("permission") 
						? config.permission 
						: false
				};
				obj["maxWidth"] = 800;
			}
			if (
				String(hValue).toUpperCase() ==
				String("line_items_count").toUpperCase()
			) {
				obj["cellRenderer"] = "CustomizeRowGrid";
				obj["cellRendererFramework"] = CustomizeRowGrid;
				obj["cellRendererParams"] = {
					link: true,
					prefix: "",
					suffix: "",
					redirecCustom: {
						to: "LineItemList",
						entity: "campaign_id",
					},
					fieldText: hValue,
					permission: config.hasOwnProperty("permission") 
						? config.permission 
						: false
				};
				obj["maxWidth"] = 800;
			}
			if (
				String(hValue).toUpperCase() ==
				String("creative_associations_count").toUpperCase()
			) {
				obj["cellRenderer"] = "CustomizeRowGrid";
				obj["cellRendererFramework"] = CustomizeRowGrid;
				obj["cellRendererParams"] = {
					link: true,
					prefix: "",
					suffix: "",
					redirecCustom: {
						to: "LineItemEdit",
						entity: "id",
						section: 3,
					},
					fieldText: hValue,
					permission: config.hasOwnProperty("permission") 
						? config.permission 
						: false
				};
				obj["maxWidth"] = 800;
			}
			if (String(hValue).toUpperCase() == String("ctr").toUpperCase()) {
				obj["cellRenderer"] = "CustomizeRowGrid";
				obj["cellRendererFramework"] = CustomizeRowGrid;
				obj["cellRendererParams"] = {
					link: false,
					prefix: "",
					suffix: "%",
					redirecCustom: { to: "", entity: "" },
					fieldText: hValue,
					permission: config.hasOwnProperty("permission") 
						? config.permission 
						: false
				};
				obj["maxWidth"] = 800;
			}
			if (
				String(hValue).toUpperCase() ==
				String("video_complete_percent").toUpperCase()
			) {
				obj["cellRenderer"] = "CustomizeRowGrid";
				obj["cellRendererFramework"] = CustomizeRowGrid;
				obj["cellRendererParams"] = {
					link: false,
					prefix: "",
					suffix: "%",
					redirecCustom: { to: "", entity: "" },
					fieldText: hValue,
					permission: config.hasOwnProperty("permission") 
						? config.permission 
						: false
				};
				obj["maxWidth"] = 800;
			}
			if (
				String(hValue).toUpperCase() ==
				String("parameters").toUpperCase()
			) {
				obj["minWidth"] = 250;
				obj["cellClass"] = "d-inline ag-cell-inline inline-block pa-1";
				obj["cellRenderer"] = "CustomizeRowObjectGrid";
				obj["cellRendererFramework"] = CustomizeRowObjectGrid;
			}
			columnDefs.push(obj);
		});
		if (config.hasOwnProperty("eventActions") ? config.eventActions : true) {
			columnDefs.push(
				{
					headerName: i18n.t('common.actions.actions'),
					field: "actions",
					minWidth: config.hasOwnProperty("minWidthActions") ? config.minWidthActions : 150,
					maxWidth: config.hasOwnProperty("maxWidthActions") ? config.maxWidthActions : 150,
					pinned: 'right',
					lockPosition: 'right',
					flex: 1,
					resizable: false,
					filter: false,
					cellRenderer: "ActionsTable",
					cellRendererFramework: ActionsTable,
					cellRendererParams: {
						entityName: config.hasOwnProperty("entityName") ? config.entityName : "",
						contextT: config.hasOwnProperty("context") ? config.context : null,
						redirect: config.hasOwnProperty("redirect") ? config.redirect : "",
						activityLog: config.hasOwnProperty("activityLog") ? config.activityLog : "",
						edit: config.hasOwnProperty("edit") ? config.edit : false,
						archived: config.hasOwnProperty("archived") ? config.archived : false,
						elegible: config.hasOwnProperty("elegible") ? config.elegible : false,
						editReport: config.hasOwnProperty("editReport") ? config.editReport : false,
						sendEmail: config.hasOwnProperty("sendEmail") ? config.sendEmail : "",
						sendEmailMyReport: config.hasOwnProperty("sendEmailMyReport") ? config.sendEmailMyReport : "",
						duplicated: config.hasOwnProperty("duplicated") ? config.duplicated : false,
						log: config.hasOwnProperty("log") ? config.log : false,
						convertLatLong: config.hasOwnProperty("convertLatLong") ? config.convertLatLong : false,
						download: config.hasOwnProperty("download") ? config.download : false,
						downloadMyReport: config.hasOwnProperty("downloadMyReport") ? config.downloadMyReport : false,
						scheduler: config.hasOwnProperty("scheduler") ? config.scheduler : false,
						checkStatus: config.hasOwnProperty("checkStatus") ? config.checkStatus : false,
						checkDisableEdit: config.hasOwnProperty("checkDisableEdit") ? config.checkDisableEdit : false,
						checkDisableEditPrivate: config.hasOwnProperty("checkDisableEditPrivate") ? config.checkDisableEditPrivate : false,
						list: config.hasOwnProperty("list") ? config.list : false,
						delete: config.hasOwnProperty("delete") ? config.delete : false,
						delete_schedule: config.hasOwnProperty("delete_schedule") ? config.delete_schedule : false,
						deleteAction: config.hasOwnProperty("deleteAction") ? config.deleteAction : false,
						permission: config.hasOwnProperty("permission") ? config.permission : false
					}
				}
			);
		}
		return columnDefs;
	}
}
