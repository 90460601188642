import { AxiosGet, AxiosPost } from "@/services/axios-service";
import { catchServiceErrors } from "@/utils/services-global";
import ROUTES from "@/api/routes";
import { GenerateReportTypeEnum } from "@/interfaces/reports/v2/report";

const ROUTE = ROUTES.REPORT;

class LookerService {
  private configUrlMap = {
    [GenerateReportTypeEnum.PERFORMACE_CAMPAIGN_SP]: {
      url: ROUTE.REPORT_PERFORMANCE_CAMPAIGN_LOOKER_URL_ROUTE,
      method: "GET",
    },
    [GenerateReportTypeEnum.PARTIAL_REPORT_SP]: {
      url: ROUTE.REPORT_PARTIAL_LOOKER_URL_ROUTE,
      method: "POST",
    },
    [GenerateReportTypeEnum.FINAL_REPORT_SP]: {
      url: ROUTE.REPORT_FINAL_LOOKER_URL_ROUTE,
      method: "POST",
    },
  };

  /**
   * Obtiene la URL de Looker por tipo de reporte
   * @param reportType Tipo de reporte
   * @param data Datos adicionales para el reporte
   * @returns URL de Looker o error
   */
  async fetchLookerUrlByReportType(reportType: GenerateReportTypeEnum, data?: any) {
    const config = this.configUrlMap[reportType];
    if (!config) {
      throw new Error(`URL not found for the report type: ${reportType}`);
    }

    try {
      const response = await this.makeRequest(config, data);
      return response?.data?.response;
    } catch (error) {
      return await catchServiceErrors(error);
    }
  }

  /**
   * Realiza una solicitud HTTP según el tipo de configuración
   * @param config Configuración de la solicitud
   * @param data Datos adicionales para la solicitud
   * @returns Respuesta de la solicitud
   */
  private async makeRequest(config: { url: string; method: string }, data?: any) {
    if (config.method === "GET") {
      return await AxiosGet(config.url);
    } else if (config.method === "POST") {
      return await AxiosPost(config.url, data);
    }

    throw new Error(`Method not supported: ${config.method}`);
  }
}

export default new LookerService();
