import { GenerateReportTypeEnum } from "@/interfaces/reports/v2/report";
import LookerService from "@/services/looker-service";
import { catchError } from "@/store/Util";

export const LookerModule = {
  namespaced: true,
  state: () => ({}),
  mutations: {},
  getters: {},
  actions: {
    /**
     * Obtiene la URL de Looker por tipo de reporte
     * @param reportType Tipo de reporte
     * @returns URL de Looker
     */
    async getLookerUrlByReportType({ commit }, reportType: GenerateReportTypeEnum) {
      try {
        const response: string = await LookerService.fetchLookerUrlByReportType(reportType);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },

    async getLookerUrlByReportTypeWithData(
      { commit },
      payload: { reportType: GenerateReportTypeEnum; data: { campaign_id: number } },
    ) {
      try {
        const response: string = await LookerService.fetchLookerUrlByReportType(payload.reportType, payload.data);
        return await Promise.resolve(response);
      } catch (error) {
        catchError(this, error);
        return await Promise.reject(error);
      }
    },
  },
};
