import { reactive } from 'vue';
import { AxiosDelete, AxiosGet, AxiosPost, GetData, GetErrors, GetMessage } from './axios-service';

export const REPORT_MY_REPORT_SEND_MAIL_ROUTE = "/api/reports/send_email_report_job";
export const REPORT_MY_SCHEDULER = "/api/reports_schedule";
export const REPORT = "/api/reports";
export const REPORT_TYPE= "/api/reports/get_types";
import ROUTES from "@/api/routes";
import { catchServiceErrors, prepareParams, modes} from "@/utils/services-global";
const ENTITY: string = "report";
const ROUTE = ROUTES.REPORT;
function matchedRoutes() {
    return {
        scheduler_type: ROUTE.REPORT_GET_SCHEDULER_TYPES_ROUTE,
        report_my_report: ROUTE.REPORT_MY_REPORT_ROUTE,
    };
}

function getFiltersMyReport(filters: any): string {
    let filter = "";

    const id = !!filters.id ? filters.id : "";
    const report_type_name = !!filters.report_type_name ? filters.report_type_name : "";
    const nameMyReport = !!filters.nameMyReport ? filters.nameMyReport : "";
    const user_name = !!filters.user_name ? filters.user_name : "";
    const status = !!filters.report_status ? filters.report_status : "";
    const created_at = !!filters.created_at ? filters.created_at : "";

    var symbol = "";

    if (id) {
    filter += `${symbol}filters[id]=${id}`;
    symbol = "&";
    }

    if (report_type_name) {
    filter += `${symbol}filters[report_type_name]=${report_type_name}`;
    symbol = "&";
    }

    if (nameMyReport) {
    filter += `${symbol}filters[name]=${nameMyReport}`;
    symbol = "&";
    }

    if (user_name) {
    filter += `${symbol}filters[user_name]=${user_name}`;
    symbol = "&";
    }

    if (status) {
    filter += `${symbol}filters[status]=${status}`;
    symbol = "&";
    }

    if (created_at) {
    filter += `${symbol}filters[start_date]=${created_at}`;
    symbol = "&";
    }

    return filter;
}

export const MyReportService = {

    async paginatedMyReport(params: any) {
        try {
            let filter = "";
            if (params.hasOwnProperty("filters")) {
                filter = getFiltersMyReport(params.filters);
            }
    
            const url = await prepareParams({
                route: matchedRoutes()["report_my_report"],
                entity: ENTITY,
                mode: modes.PAGINATED,
                filter: filter,
                ...params,
            });
    
            const response = await AxiosGet(url);
            return Promise.resolve(GetData(response));
        } catch (error) {
            return await catchServiceErrors(error);
        }
    },
    
    handleAppendEmailTo(email: string) {
        if (this.form.emails.indexOf(email) === -1) {
            this.form.emails.push(email);
            this.form.email_to = '';
        }
    },

    handleDeleteEmailTo(email: string) {
        const index = this.form.emails.indexOf(email);
        if (index > -1) {
            this.form.emails.splice(index, 1);
        }
    },
    
    async sendEmailMyReport(params: any){
        try {
            const payload: Object = {
                "email_subject" : params.email_subject,
                "emails" : JSON.parse(JSON.stringify(params.emails || []))
            };
            const response = await AxiosPost(`${REPORT_MY_REPORT_SEND_MAIL_ROUTE}/${params.id}`, payload);
        return Promise.resolve(GetData(response));
        } catch (error) {
            return Promise.reject({
                success: false,
                message: GetMessage(error),
                errors: GetErrors(error),
            });
        }
    },

    async getSchedulerTypes() {
        try {
            const match = "scheduler_type";
            const url = matchedRoutes()[match];
            const response = await AxiosGet(url);
            return Promise.resolve(GetData(response));
        } catch (error) {
            return await catchServiceErrors(error);
        }
    },

    async getResourceScheduler(params: any){
        try {
            const response = await AxiosGet(`${REPORT_MY_SCHEDULER}/${params}`);
        return Promise.resolve(GetData(response));
        } catch (error) {
            return Promise.reject({
                success: false,
                message: GetMessage(error),
                errors: GetErrors(error),
            });
        }
    },

    async getResourceReport(params: any){
        try {
            const response = await AxiosGet(`${REPORT}/${params}`);
        return Promise.resolve(GetData(response));
        } catch (error) {
            return Promise.reject({
                success: false,
                message: GetMessage(error),
                errors: GetErrors(error),
            });
        }
    },

    async getResourceReportType(){
        try {
            const response = await AxiosGet(`${REPORT_TYPE}`);
        return Promise.resolve(GetData(response));
        } catch (error) {
            return Promise.reject({
                success: false,
                message: GetMessage(error),
                errors: GetErrors(error),
            });
        }
    },

    async deleteMyReport(params: any) {
      try {
        const response = await AxiosDelete(`${REPORT}/${params}`);
        return Promise.resolve(GetData(response));
      } catch (error) {
        return Promise.reject({
          success: false,
          message: GetMessage(error),
          errors: GetErrors(error),
        });
      }
    }
};
