import { ApiRoutes } from "@/interfaces/Core/route";
import {
  ACCOUNT_LIST_ROUTE,
  ACCOUNT_ROUTE,
  ACTIVITY_LOG_ADVERTISER_ROUTE,
  ACTIVITY_LOG_CAMPAIGN_ROUTE,
  ACTIVITY_LOG_CREATIVE_ROUTE,
  ACTIVITY_LOG_LINE_ITEM_ROUTE,
  AD_POSITION_ROUTE,
  ADVERTISER_CATEGORIES_ROUTE,
  ADVERTISER_MAKERS_ROUTE,
  ADVERTISER_ROUTE,
  APP_BUNDLE_LIST_ROUTE,
  APP_ID_ROUTE,
  APP_NAME_ROUTE,
  AUCTION_TYPE_ROUTE,
  AUDIENCE_AGES_ROUTE,
  AUDIENCE_APP_VISITED_ROUTE,
  AUDIENCE_BRANDS_ROUTE,
  AUDIENCE_BROWSER_ROUTE,
  AUDIENCE_CAMPAIGN_INTERESTS_ROUTE,
  AUDIENCE_CATEGORIES_ROUTE,
  AUDIENCE_CITIES_ROUTE,
  AUDIENCE_CITY_SEEN_ROUTE,
  AUDIENCE_DATES_ROUTE,
  AUDIENCE_DEPARTMENTS_ROUTE,
  AUDIENCE_DEVICE_TYPE_ROUTE,
  AUDIENCE_DISTANCES_ROUTE,
  AUDIENCE_GENDERS_ROUTE,
  AUDIENCE_HOUR_DAY_ROUTE,
  AUDIENCE_IDENTIFIER_TYPE_ROUTE,
  AUDIENCE_LANGUAGE_ROUTE,
  AUDIENCE_MAKE_ROUTE,
  AUDIENCE_NAMES_ROUTE,
  AUDIENCE_NAVIGATION_BEHAVIOUR_ROUTE,
  AUDIENCE_NEIGHBORHOOD_RESIDENCE_ROUTE,
  AUDIENCE_NEIGHBORHOOD_ROUTE,
  AUDIENCE_OPERATING_SYSTEM_ROUTE,
  AUDIENCE_OPERATOR_ROUTE,
  AUDIENCE_RESIDENCE_CITIES_ROUTE,
  AUDIENCE_SITES_VISITED_ROUTE,
  AUDIENCE_SOCIOECONOMICS_ROUTE,
  AUDIENCE_SOCIOECONOMICS_ROUTE_BR,
  AUDIENCE_SUBCATEGORIES_ROUTE,
  AUDIENCE_WEEK_DAY_ROUTE,
  BID_RATE_ROUTE,
  BID_SHADING_ROUTE,
  BID_STRATEGY_ROUTE,
  BIDMODELS_LIST_ROUTE,
  BUDGET_ROUTE,
  CAMPAIGN_ASSOCIATIONS_EVENT_ROUTE,
  CAMPAIGN_ROUTE,
  CAMPAIGN_TERMS_ROUTE,
  CAMPAING_KPI_ROUTE,
  CAMPAING_PACING_ROUTE,
  CITY_ROUTE,
  CLONE_CAMPAIGN_ROUTE,
  CLONE_CREATIVE_ROUTE,
  CLONE_LINE_ITEM_ROUTE,
  CODE_QR_GOOGLE_ROUTE,
  CONTENT_CATEGORY_ROUTE,
  CONTENT_LENGUAGE_ROUTE,
  CONTENT_RATINGS_ROUTE,
  CONTINENT_ROUTE,
  COUNTRY_ROUTE,
  CREATIVE_ADDONS_ROUTE,
  CREATIVE_APIS_ROUTE,
  CREATIVE_ASSETS_ROUTE,
  CREATIVE_ASSOCIATE_ROUTE,
  CREATIVE_ASSOCIATION_ROUTE,
  CREATIVE_AUDIO_MIME_ROUTE,
  CREATIVE_BULK_ROUTE,
  CREATIVE_EXPANDABLE_DIRECTION_ROUTE,
  CREATIVE_EXPANDABLE_TYPE_ROUTE,
  CREATIVE_LINE_ASSOCIATIONS_ROUTE,
  CREATIVE_METHOD_ROUTE,
  CREATIVE_MIME_ROUTE,
  CREATIVE_ROUTE,
  CREATIVE_RULE_ROUTE,
  CREATIVE_SIZE_ROUTE,
  CREATIVE_TAG_ROUTE,
  CREATIVE_TEMPLATE_ROUTE,
  CREATIVE_TYPE_ROUTE,
  CREATIVE_VENDOR_ROUTE,
  CREATIVE_VIDEO_ROUTE,
  CURRENCY_ROUTE,
  CUSTOM_LIST_CLEAR_ROUTE,
  CUSTOM_LIST_EXCHANGE_ROUTE,
  CUSTOM_LIST_ROUTE,
  CUSTOM_LIST_TYPES_ROUTE,
  DEAL_ID_LIST_ROUTE,
  DEAL_ID_ROUTE,
  DELIVERY_MODIFIER_ROUTE,
  DOMAIN_LIST_ROUTE,
  DOMAIN_ROUTE,
  EMAIL_RESEND_ROUTE,
  ENVIROMENT_REWARDED_ROUTE,
  ENVIROMENT_TPFRAME_ROUTE,
  ENVIROMENT_TYPE_ROUTE,
  ENVIROMENT_VIDEO_ROUTE,
  EVENT_ASSOCIATIONS_CAMPAIGN_ROUTE,
  EVENT_ASSOCIATIONS_LINE_ROUTE,
  EVENT_ATTRIBUTIONS_ROUTE,
  EVENT_GENERATE_TAG_ROUTE,
  EVENT_ROUTE,
  EVENT_TAG_TYPES_ROUTE,
  EVENT_TYPES_ROUTE,
  EXT_BUY_MODELS_ROUTE,
  EXT_CAMPAIGN_CREATIVE_ASPECT_RATIO_ROUTE,
  EXT_CAMPAIGN_CREATIVE_DISPLAY_ROUTE,
  EXT_CAMPAIGN_CREATIVE_NATIVE_ROUTE,
  EXT_CAMPAIGN_CREATIVE_ROUTE,
  EXT_CAMPAIGN_CREATIVE_SIZE_ROUTE,
  EXT_CAMPAIGN_CREATIVE_VIDEO_ROUTE,
  EXT_CAMPAIGN_MMP_ROUTE,
  EXT_CAMPAIGN_NOTES_ROUTE,
  EXT_CAMPAIGN_OWNERS_ROUTE,
  EXT_CAMPAIGN_PROVIDERS_ROUTE,
  EXT_CAMPAIGN_ROUTE,
  EXT_CAMPAIGN_SEND_EMAIL_ROUTE,
  EXT_CAMPAIGN_STATUS_ROUTE,
  EXT_CAMPAIGN_TYPES_CREATIVE_ROUTE,
  EXT_CAMPAIGN_TYPES_ROUTE,
  FORGOT_ROUTE,
  INITIALIZE_ROUTE,
  INTERSTITIAL_FLAGS,
  INTESTITIAL_TYPES,
  INVENTORY_SOURCE_ROUTE,
  INVENTORY_VIDEO_APIS,
  LAT_LONG_ROUTE,
  LINE_ASSOCIATIONS_EVENT_ROUTE,
  LINE_ITEM_BID_WIN_ROUTE,
  LINE_ITEM_TYPE_ROUTE,
  LINE_ITEMS_MODIFIER_ASSOCIATION_ROUTE,
  LINE_ITEMS_ROUTE,
  LINE_PACING_ROUTE,
  LIST_ITEM_ROUTE,
  LOCATION_TYPE_ROUTE,
  LOGIN_ROUTE,
  LOGOUT_ROUTE,
  LOOKALIKE_ACTIVATE_ROUTE,
  LOOKALIKE_CREATE_ROUTE,
  LOOKALIKE_PAGINATED_ROUTE,
  LOOKER_ENDPOINT,
  MATCHING_ROUTE,
  MODELS_CREATE_ROUTE,
  MODELS_METRICS_LIST_ROUTE,
  MODELS_PAGINATED_ROUTE,
  MODIFIER_ASSOCIATION_ROUTE,
  MODIFIER_MODULE_ROUTE,
  MODIFIER_ROUTE,
  MODIFIER_TYPE_ROUTE,
  MODULE_MODIFIER,
  NATIVE_NATIVE_LAYOUT_ROUTE,
  NOTIFICATION_DISMISS,
  NOTIFICATION_ROUTE,
  NOTIFICATION_SET_ACTIVE,
  NOTIFICATION_SETTINGS_ROUTE,
  PERMISSION_ROUTE,
  PERSON_AUDIENCE_MAP,
  PERSON_BRAND_ROUTE,
  PERSON_CATEGORY_ROUTE,
  PERSON_CITY_ROUTE,
  PERSON_COUNTRY_ROUTE,
  PERSON_DPTO_ROUTE,
  PERSON_EXPORT_AUDIENCE,
  PERSON_EXPORT_TO_LIST_ROUTE,
  PERSON_FILTER_AUDIENCE_ROUTE,
  PERSON_GENDER_AGE,
  PERSON_GENDER_AGE_DOWNLOAD,
  PERSON_GENERATE_DEMO,
  PERSON_GENERATED_AUDIENCE,
  PERSON_GET_BRANDS_PRIVATE_POIS_ROUTE,
  PERSON_GET_CATEGORIES_PRIVATE_POIS_ROUTE,
  PERSON_GET_CITIES_PRIVATE_POIS_ROUTE,
  PERSON_GET_COUNTRIES_PRIVATE_POIS_ROUTE,
  PERSON_GET_DEPARTMENTS_PRIVATE_POIS_ROUTE,
  PERSON_GET_NAMES_PRIVATE_POIS_ROUTE,
  PERSON_GET_PRIVATE_POIS_ROUTE,
  PERSON_GET_SUBCATEGORIES_PRIVATE_POIS_ROUTE,
  PERSON_HOUR_DAY_WEEK,
  PERSON_NAME_ROUTE,
  PERSON_NEIGHBORHOOD_ROUTE,
  PERSON_POIS_GRAPHIC,
  PERSON_POIS_MAP,
  PERSON_PRIVATE_NEIGHBORHOOD_ROUTE,
  PERSON_PRIVATE_POIS_ROUTE,
  PERSON_REFRESH_AUDIENE,
  PERSON_SUBCATEGORY_ROUTE,
  PERSONA_CREATE_LAYER_PRIVATE_POIS_ROUTE,
  PLACEMENT_ROUTE,
  PLANNING,
  PLATFORM_BANDWITDH,
  PLATFORM_BROWSER_ROUTE,
  PLATFORM_BROWSER_VERSION_ROUTE,
  PLATFORM_CARRIER_ROUTE,
  PLATFORM_DEVICE_MAKE_ROUTE,
  PLATFORM_DEVICE_MODEL_ROUTE,
  PLATFORM_DEVICE_SCREEN_SIZE_ROUTE,
  PLATFORM_DEVICE_TYPE_ROUTE,
  PLATFORM_OPERATING_SYSTEM_ROUTE,
  PLATFORM_OPERATING_SYSTEM_VERSION_ROUTE,
  POLL_QUESTIONS_ROUTE,
  POLL_RESPONSES_ROUTE,
  POLLS_ROUTE,
  POLL_CANCEL_ROUTE,
  PROFILE_ROUTE,
  PUBLISER_ID_LIST_ROUTE,
  PUBLISER_ID_ROUTE,
  REFRESH_ROUTE,
  REGION_ROUTE,
  REPORT_ACCOUNT_SP_ROUTE,
  REPORT_ADVERTISER_ROUTE,
  REPORT_ALL_TYPE_ROUTE,
  REPORT_CAMPAIGN_ROUTE,
  REPORT_COUNTRY_CAMPAIGN_ROUTE,
  REPORT_COUNTRY_ROUTE,
  REPORT_CREATIVE_ROUTE,
  REPORT_DATA_RANGE_ROUTE,
  REPORT_DATA_RANGE_SP_ROUTE,
  REPORT_DEMAND_CAMPAIGN_INSIGHT_ROUTE,
  REPORT_DEMAND_GEO_ROUTE,
  REPORT_DEMAND_LIST_ROUTE,
  REPORT_DEMAND_STORE_ATTRIBUTION_ROUTE,
  REPORT_DEMAND_UNIQUE_DEVICES_ROUTE,
  REPORT_DIMENSION_AND_METRIC_SP_ROUTE,
  REPORT_DOWNLOAD_ON_DEMAND_ROUTE,
  REPORT_FIELD_ROUTE,
  REPORT_FILTERS_SP,
  REPORT_FORMAT_TYPE_ROUTE,
  REPORT_FORMAT_TYPE_SP_ROUTE,
  REPORT_GET_DIMENSIONS_ROUTE,
  REPORT_GET_METRICS_ROUTE,
  REPORT_GET_SCHEDULER_TYPES_ROUTE,
  REPORT_LINE_ROUTE,
  REPORT_LIST_ROUTE,
  REPORT_ON_DEMAND_ROUTE,
  REPORT_MY_REPORT_ROUTE,
  REPORT_ROUTE,
  REPORT_SCHEDULER_BULK_ROUTE,
  REPORT_SCHEDULER_ROUTE,
  REPORT_SP_ROUTE,
  REPORT_TYPE_ROUTE,
  REPORT_PERFORMANCE_CAMPAIGN_LOOKER_URL_ROUTE,
  REPORT_PARTIAL_LOOKER_URL_ROUTE,
  REPORT_FINAL_LOOKER_URL_ROUTE,
  RESET_ROUTE,
  SEGMENT_GENERATED_TAG_ROUTE,
  SEGMENT_PROVIDERS_ROUTER,
  SEGMENT_SEARCH,
  SEGMENT_TAG_ROUTE,
  SEGMENT_THIRD_TREE,
  SEGMENT_TREE_VIEW_ROUTE,
  SEGMENTS_CATEGORY_LIST_ROUTE,
  SEGMENTS_CATEGORY_ROUTE,
  SEGMENTS_CATEGORY_THIRDPARTY_ROUTE,
  SEGMENTS_ROUTE,
  SEGMENTS_THIRDPARTY_ROUTE,
  SET_COLOR_ROUTE,
  SET_TO_FACTOR_ROUTE,
  SITE_LIST_ROUTE,
  SITE_ROUTE,
  STRATEGY_OPTIMIZATION_ROUTE,
  STRATEGY_ROUTE,
  SUMMARY_TARGETING_EXPRESSIONS_ROUTE,
  TARGETING_KEY_ROUTE,
  TARGETING_LOCATION_TYPE_ROUTE,
  TARGETING_MODULE_ROUTE,
  TARGETING_PREDICATE_ROUTE,
  TARGETING_ROUTE,
  TIMEZONE_ROUTE,
  TRANSLATION_LANGUAGES,
  TWOFA_ROUTE,
  TYPES_FRECUENCY_CAPS_ROUTE,
  UNIT_TIME_ROUTE,
  USER_ROUTE,
  VIDEO_COMPANION_REQUIRED,
  VIDEO_PLACEMENT_TYPE,
  VIDEO_PLAYBACK_METHOD,
  VIDEO_PLAYER_SIZE,
  VIDEO_START_DELAY,
  TIMEZONE_ASSOCIATION_ROUTE,
  PACING_BEHAVIOUR_ROUTE,
  CATCHUP_BEHAVIOUR_ROUTE,
} from "./constants";

// @ts-ignore
export default {
  PLANNING,
  PERSON: {
    PERSON_COUNTRY_ROUTE,
    PERSON_DPTO_ROUTE,
    PERSON_CITY_ROUTE,
    PERSON_CATEGORY_ROUTE,
    PERSON_SUBCATEGORY_ROUTE,
    PERSON_BRAND_ROUTE,
    PERSON_NAME_ROUTE,
    PERSON_POIS_GRAPHIC,
    PERSON_REFRESH_AUDIENE,
    PERSON_POIS_MAP,
    PERSON_AUDIENCE_MAP,
    PERSON_EXPORT_AUDIENCE,
    PERSON_PRIVATE_POIS_ROUTE,
    PERSON_GET_PRIVATE_POIS_ROUTE,
    PERSON_GET_COUNTRIES_PRIVATE_POIS_ROUTE,
    PERSON_GET_DEPARTMENTS_PRIVATE_POIS_ROUTE,
    PERSON_GET_CITIES_PRIVATE_POIS_ROUTE,
    PERSON_GET_CATEGORIES_PRIVATE_POIS_ROUTE,
    PERSON_GET_SUBCATEGORIES_PRIVATE_POIS_ROUTE,
    PERSON_GET_BRANDS_PRIVATE_POIS_ROUTE,
    PERSON_GET_NAMES_PRIVATE_POIS_ROUTE,
    PERSONA_CREATE_LAYER_PRIVATE_POIS_ROUTE,
    PERSON_EXPORT_TO_LIST_ROUTE,
    GENERATE: {
      PERSON_GENERATED_AUDIENCE,
      PERSON_GENERATE_DEMO,
      PERSON_FILTER_AUDIENCE_ROUTE,
    },
    POIS: {
      PERSON_NEIGHBORHOOD_ROUTE,
      PERSON_PRIVATE_NEIGHBORHOOD_ROUTE,
      PERSON_POIS_GRAPHIC,
    },
    AUDIENCE: {
      AUDIENCE_DATES_ROUTE,
      AUDIENCE_DISTANCES_ROUTE,
      AUDIENCE_WEEK_DAY_ROUTE,
      AUDIENCE_HOUR_DAY_ROUTE,

      // AUDIENCE pois
      AUDIENCE_CATEGORIES_ROUTE,
      AUDIENCE_SUBCATEGORIES_ROUTE,
      AUDIENCE_DEPARTMENTS_ROUTE,
      AUDIENCE_CITIES_ROUTE,
      AUDIENCE_NEIGHBORHOOD_ROUTE,
      AUDIENCE_BRANDS_ROUTE,
      AUDIENCE_NAMES_ROUTE,

      // AUDIENCE demographics
      AUDIENCE_GENDERS_ROUTE,
      AUDIENCE_AGES_ROUTE,
      AUDIENCE_RESIDENCE_CITIES_ROUTE,
      AUDIENCE_NEIGHBORHOOD_RESIDENCE_ROUTE,
      AUDIENCE_SOCIOECONOMICS_ROUTE,
      AUDIENCE_SOCIOECONOMICS_ROUTE_BR,

      // AUDIENCE online_behaviour
      AUDIENCE_IDENTIFIER_TYPE_ROUTE,
      AUDIENCE_NAVIGATION_BEHAVIOUR_ROUTE,
      AUDIENCE_CAMPAIGN_INTERESTS_ROUTE,
      AUDIENCE_APP_VISITED_ROUTE,
      AUDIENCE_SITES_VISITED_ROUTE,
      AUDIENCE_CITY_SEEN_ROUTE,

      // AUDIENCE device
      AUDIENCE_BROWSER_ROUTE,
      AUDIENCE_LANGUAGE_ROUTE,
      AUDIENCE_MAKE_ROUTE,
      AUDIENCE_DEVICE_TYPE_ROUTE,
      AUDIENCE_OPERATING_SYSTEM_ROUTE,
      AUDIENCE_OPERATOR_ROUTE,

      // GENDER AGE
      PERSON_GENDER_AGE,
      PERSON_GENDER_AGE_DOWNLOAD,

      // HEAT_MAP
      PERSON_HOUR_DAY_WEEK,

      // AUDIENCE_GRAPHICS_CATEGORY_ROUTE,
      // AUDIENCE_GRAPHICS_DAY_HOUR_ROUTE,
      // AUDIENCE_GRAPHICS_DATE_ROUTE,
      // AUDIENCE_GRAPHICS_TOTAL_CATEGORIES_ROUTE,
      // AUDIENCE_GRAPHICS_TOTAL_SUMMARY_ROUTE,
    },
  },
  REPORT: {
    REPORT_ROUTE,
    REPORT_TYPE_ROUTE,
    REPORT_DATA_RANGE_ROUTE,
    REPORT_ADVERTISER_ROUTE,
    REPORT_CAMPAIGN_ROUTE,
    REPORT_LINE_ROUTE,
    REPORT_CREATIVE_ROUTE,
    REPORT_FORMAT_TYPE_ROUTE,
    REPORT_FIELD_ROUTE,
    REPORT_LIST_ROUTE,
    LOOKER_ENDPOINT,
    REPORT_GET_DIMENSIONS_ROUTE,
    REPORT_ALL_TYPE_ROUTE,
    REPORT_GET_METRICS_ROUTE,
    REPORT_GET_SCHEDULER_TYPES_ROUTE,
    REPORT_SCHEDULER_ROUTE,
    REPORT_COUNTRY_ROUTE,
    REPORT_DEMAND_STORE_ATTRIBUTION_ROUTE,
    REPORT_DEMAND_GEO_ROUTE,
    REPORT_DEMAND_CAMPAIGN_INSIGHT_ROUTE,
    REPORT_DEMAND_UNIQUE_DEVICES_ROUTE,
    REPORT_DEMAND_LIST_ROUTE,
    REPORT_ON_DEMAND_ROUTE,
    REPORT_MY_REPORT_ROUTE,
    REPORT_COUNTRY_CAMPAIGN_ROUTE,
    REPORT_DOWNLOAD_ON_DEMAND_ROUTE,
    REPORT_SCHEDULER_BULK_ROUTE,
    REPORT_DIMENSION_AND_METRIC_SP_ROUTE,
    REPORT_SP_ROUTE,
    REPORT_FILTERS_SP,
    REPORT_DATA_RANGE_SP_ROUTE,
    REPORT_FORMAT_TYPE_SP_ROUTE,
    REPORT_ACCOUNT_SP_ROUTE,
    REPORT_PERFORMANCE_CAMPAIGN_LOOKER_URL_ROUTE,
    REPORT_PARTIAL_LOOKER_URL_ROUTE,
    REPORT_FINAL_LOOKER_URL_ROUTE,
  },
  ADVERTISER: {
    ADVERTISER_ROUTE,
    ADVERTISER_CATEGORIES_ROUTE,
    ADVERTISER_MAKERS_ROUTE,
  },
  AD_SIZE: {
    CREATIVE_SIZE_ROUTE,
  },
  APP_SITE: {
    APP_BUNDLE_LIST_ROUTE,
    APP_ID_ROUTE,
    APP_NAME_ROUTE,
    DEAL_ID_ROUTE,
    DEAL_ID_LIST_ROUTE,
    DOMAIN_ROUTE,
    PLACEMENT_ROUTE,
    PUBLISER_ID_ROUTE,
    PUBLISER_ID_LIST_ROUTE,
    SITE_ROUTE,
    SITE_LIST_ROUTE,
  },
  AUTH: {
    LOGIN_ROUTE,
    LOGOUT_ROUTE,
    PROFILE_ROUTE,
    FORGOT_ROUTE,
    RESET_ROUTE,
    REFRESH_ROUTE,
    PERMISSION_ROUTE,
    INITIALIZE_ROUTE,
    ACCOUNT_ROUTE,
    ACCOUNT_LIST_ROUTE,
    CODE_QR_GOOGLE_ROUTE,
    TWOFA_ROUTE,
    SET_TO_FACTOR_ROUTE,
  },
  BID: {
    BID_SHADING_ROUTE,
    BID_STRATEGY_ROUTE,
    BID_RATE_ROUTE,
  },
  BUDGET: {
    BUDGET_ROUTE,
  },
  CAMPAIGN: {
    CAMPAIGN_ROUTE,
    MODIFIER_ASSOCIATION_ROUTE,
    CAMPAIGN_ASSOCIATIONS_EVENT_ROUTE,
    CAMPAIGN_TERMS_ROUTE,
  },
  CONTENT: {
    CONTENT_CATEGORY_ROUTE,
    CONTENT_LENGUAGE_ROUTE,
    CONTENT_RATINGS_ROUTE,
  },
  CREATIVES: {
    CREATIVE_ROUTE,
    CREATIVE_METHOD_ROUTE,
    CREATIVE_TYPE_ROUTE,
    CREATIVE_TEMPLATE_ROUTE,
    CREATIVE_VENDOR_ROUTE,
    CREATIVE_RULE_ROUTE,
    CREATIVE_TAG_ROUTE,
    CREATIVE_MIME_ROUTE,
    CREATIVE_AUDIO_MIME_ROUTE,
    CREATIVE_EXPANDABLE_TYPE_ROUTE,
    CREATIVE_EXPANDABLE_DIRECTION_ROUTE,
    CREATIVE_VIDEO_ROUTE,
    CREATIVE_APIS_ROUTE,
    CREATIVE_ASSOCIATE_ROUTE,
    CREATIVE_ADDONS_ROUTE,
    CREATIVE_ASSETS_ROUTE,
    CREATIVE_BULK_ROUTE,
    CREATIVE_ASSOCIATION_ROUTE,
    CREATIVE_LINE_ASSOCIATIONS_ROUTE,
  },
  CURRENCY: {
    CURRENCY_ROUTE,
  },
  CUSTOM_LIST: {
    CUSTOM_LIST_ROUTE,
    CUSTOM_LIST_EXCHANGE_ROUTE,
    CUSTOM_LIST_TYPES_ROUTE,
    CUSTOM_LIST_CLEAR_ROUTE,
    LIST_ITEM_ROUTE,
    TYPES_FRECUENCY_CAPS_ROUTE,
  },
  DOMAIN: {
    DOMAIN_LIST_ROUTE,
  },
  ENVIROMENT: {
    ENVIROMENT_TYPE_ROUTE,
    ENVIROMENT_TPFRAME_ROUTE,
    ENVIROMENT_VIDEO_ROUTE,
    ENVIROMENT_REWARDED_ROUTE,
  },
  EXCHANGE: {
    AUCTION_TYPE_ROUTE,
    INVENTORY_SOURCE_ROUTE,
  },
  GEO: {
    COUNTRY_ROUTE,
    REGION_ROUTE,
    CITY_ROUTE,
    LAT_LONG_ROUTE,
    LOCATION_TYPE_ROUTE,
  },
  INVENTORY: {
    AD_POSITION_ROUTE,
    AUCTION_TYPE_ROUTE,
    ENVIROMENT_TYPE_ROUTE,
    INVENTORY_SOURCE_ROUTE,
    INTERSTITIAL_FLAGS,
    INTESTITIAL_TYPES,
    INVENTORY_VIDEO_APIS,
    DEAL_ID_LIST_ROUTE,
    PLACEMENT_ROUTE,
    PUBLISER_ID_LIST_ROUTE,
    SITE_LIST_ROUTE,
  },
  KPI: {
    CAMPAING_KPI_ROUTE,
  },
  CATCHUP: {
    CATCHUP_BEHAVIOUR_ROUTE,
  },
  LINE_ITEMS: {
    LINE_ITEM_TYPE_ROUTE,
    LINE_ITEMS_ROUTE,
    LINE_ITEMS_MODIFIER_ASSOCIATION_ROUTE,
    CREATIVE_ASSOCIATION_ROUTE,
    LINE_ITEM_BID_WIN_ROUTE,
    CREATIVE_LINE_ASSOCIATIONS_ROUTE,
    LINE_ASSOCIATIONS_EVENT_ROUTE,
  },
  MATCHING: {
    MATCHING_ROUTE,
  },
  MODIFIERS: {
    MODIFIER_TYPE_ROUTE,
    MODIFIER_MODULE_ROUTE,
    MODIFIER_ROUTE,
    MODULE_MODIFIER,
    DELIVERY_MODIFIER_ROUTE,
  },
  MODELS: {
    MODELS_CREATE_ROUTE,
    MODELS_PAGINATED_ROUTE,
    MODELS_METRICS_LIST_ROUTE,
    BIDMODELS_LIST_ROUTE,
  },
  LOOKALIKE: {
    LOOKALIKE_CREATE_ROUTE,
    LOOKALIKE_PAGINATED_ROUTE,
    LOOKALIKE_ACTIVATE_ROUTE,
  },
  PACING: {
    CAMPAING_PACING_ROUTE,
    LINE_PACING_ROUTE,
    PACING_BEHAVIOUR_ROUTE,
  },
  PLATFORM: {
    PLATFORM_BROWSER_ROUTE,
    PLATFORM_BROWSER_VERSION_ROUTE,
    PLATFORM_CARRIER_ROUTE,
    PLATFORM_DEVICE_TYPE_ROUTE,
    PLATFORM_DEVICE_MAKE_ROUTE,
    PLATFORM_DEVICE_MODEL_ROUTE,
    PLATFORM_DEVICE_SCREEN_SIZE_ROUTE,
    PLATFORM_OPERATING_SYSTEM_ROUTE,
    PLATFORM_OPERATING_SYSTEM_VERSION_ROUTE,
    PLATFORM_BANDWITDH,
  },
  STRATEGIES: {
    STRATEGY_ROUTE,
    STRATEGY_OPTIMIZATION_ROUTE,
  },
  TARGETING: {
    TARGETING_ROUTE,
    TARGETING_PREDICATE_ROUTE,
    TARGETING_KEY_ROUTE,
    TARGETING_LOCATION_TYPE_ROUTE,
    TARGETING_MODULE_ROUTE,
    SUMMARY_TARGETING_EXPRESSIONS_ROUTE,
  },
  TIMEZONE: {
    TIMEZONE_ROUTE,
    TIMEZONE_ASSOCIATION_ROUTE,
  },
  UNIT_TIME: {
    UNIT_TIME_ROUTE,
  },
  USER: {
    USER_ROUTE,
    EMAIL_RESEND_ROUTE,
    SET_COLOR_ROUTE,
  },
  MOBILE_APP: {
    APP_BUNDLE_LIST_ROUTE,
    APP_ID_ROUTE,
  },
  NATIVE: {
    NATIVE_NATIVE_LAYOUT_ROUTE,
  },
  VIDEO: {
    VIDEO_COMPANION_REQUIRED,
    VIDEO_PLAYBACK_METHOD,
    VIDEO_START_DELAY,
    VIDEO_PLAYER_SIZE,
    VIDEO_PLACEMENT_TYPE,
  },
  SEGMENTS: {
    SEGMENTS_ROUTE,
    SEGMENTS_CATEGORY_ROUTE,
    SEGMENT_PROVIDERS_ROUTER,
    SEGMENTS_CATEGORY_LIST_ROUTE,
    SEGMENTS_THIRDPARTY_ROUTE,
    SEGMENTS_CATEGORY_THIRDPARTY_ROUTE,
    SEGMENT_TAG_ROUTE,
    SEGMENT_TREE_VIEW_ROUTE,
    SEGMENT_GENERATED_TAG_ROUTE,
    SEGMENT_THIRD_TREE,
    SEGMENT_SEARCH,
  },
  CLONE: {
    CLONE_CAMPAIGN_ROUTE,
    CLONE_LINE_ITEM_ROUTE,
    CLONE_CREATIVE_ROUTE,
  },
  CONTINENT: {
    CONTINENT_ROUTE,
  },
  LOG: {
    ACTIVITY_LOG_ADVERTISER_ROUTE,
    ACTIVITY_LOG_CAMPAIGN_ROUTE,
    ACTIVITY_LOG_LINE_ITEM_ROUTE,
    ACTIVITY_LOG_CREATIVE_ROUTE,
  },
  EXT_CAMPAIGN: {
    EXT_CAMPAIGN_ROUTE,
    EXT_CAMPAIGN_TYPES_ROUTE,
    EXT_CAMPAIGN_PROVIDERS_ROUTE,
    EXT_BUY_MODELS_ROUTE,
    EXT_CAMPAIGN_MMP_ROUTE,
    EXT_CAMPAIGN_OWNERS_ROUTE,
    EXT_CAMPAIGN_STATUS_ROUTE,
    EXT_CAMPAIGN_NOTES_ROUTE,
    EXT_CAMPAIGN_CREATIVE_ROUTE,
    EXT_CAMPAIGN_CREATIVE_DISPLAY_ROUTE,
    EXT_CAMPAIGN_CREATIVE_VIDEO_ROUTE,
    EXT_CAMPAIGN_CREATIVE_NATIVE_ROUTE,
    EXT_CAMPAIGN_SEND_EMAIL_ROUTE,
    EXT_CAMPAIGN_CREATIVE_SIZE_ROUTE,
    EXT_CAMPAIGN_CREATIVE_ASPECT_RATIO_ROUTE,
    EXT_CAMPAIGN_TYPES_CREATIVE_ROUTE,
  },
  EVENT: {
    EVENT_ROUTE,
    EVENT_ATTRIBUTIONS_ROUTE,
    EVENT_TYPES_ROUTE,
    EVENT_TAG_TYPES_ROUTE,
    EVENT_GENERATE_TAG_ROUTE,
    EVENT_ASSOCIATIONS_LINE_ROUTE,
    EVENT_ASSOCIATIONS_CAMPAIGN_ROUTE,
  },
  NOTIFICATION: {
    NOTIFICATION_ROUTE,
    NOTIFICATION_SETTINGS_ROUTE,
    NOTIFICATION_SET_ACTIVE,
    NOTIFICATION_DISMISS,
  },
  POLLS: {
    POLLS_ROUTE,
    POLL_RESPONSES_ROUTE,
    POLL_QUESTIONS_ROUTE,
    POLL_CANCEL_ROUTE,
  },
  INTERNALIZATION: {
    TRANSLATION_LANGUAGES,
  },
} as ApiRoutes;

