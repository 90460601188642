import i18n from '@/plugins/i18n';
import { MyReportService } from '@/services/my-report-services';
import notificationService from '@/services/notification-service';
import { MessageTypes, Notification } from "@/interfaces/proccess";
import { resolveListParams } from "@/utils/resolveObjectArray";
import { Module } from 'vuex';
import { catchError } from '../Util';
import { ScheduleEntity } from '@/models/Reports/v2/Entity';
import { ReportDataEntity, ResourceDataEntity } from '@/interfaces/report';
import { ReportResourceDataEntity } from '@/models/Reports/v2/Report';
import { ResultPaginate } from '@/interfaces/paginated';
import { SnactType } from '@/interfaces/snack';
import { SnacktifyEntity } from '@/models/Core/Snacktify';

interface EmailState {
    form: {
        email_subject: string;
        email_to: string;
        emails: string[];
        id:null;
    };
    rules: {
        email_subject: Array<(v: string) => boolean | string>;
        email_to: Array<(v: string) => boolean | string>;
        emails: Array<(v: string) => boolean | string>;
    };
}

const MyReportModule = {
    namespaced: true,
    state: {
        form: {
            email_subject: '',
            email_to: '',
            emails: [],
            id:null
        },
        rules: {
            email_subject: [
                v => !!v || i18n.t('fieldRequired'),
                v => (v && v.length <= 100) || 'Email subject must be less than 100 characters'
            ],
            email_to: [
                v => /.+@.+\..+/.test(v) || 'Email must be valid'
            ],
            emails: [v => !!v || 'Email recipient is required',]
        },
        schedule: new ScheduleEntity(),
		report: new ReportDataEntity(),
		resources: new ResourceDataEntity(),
		resource_data: new ReportResourceDataEntity(),
        result_paginated: {} as ResultPaginate,
		pendingNotification: null,
    },
    mutations: {
		SET_MY_REPORT_PAGINATED(
			state,
			_result_paginated: ResultPaginate = {} as ResultPaginate
		) {
			state.result_paginated = _result_paginated;
		},
        SET_EMAIL_SUBJECT(state, _email_subject: string) {
            state.form.email_subject = _email_subject;
        },
        SET_EMAIL_TO(state, _email_to: string) {
            state.form.email_to = _email_to;
        },
        ADD_EMAIL(state, _emails: string) {
            if (!state.form.emails.includes(_emails)) {
                state.form.emails.push(_emails);
                state.form.email_to = '';
            }
        },
        REMOVE_EMAIL(state, email: string) {
            const index = state.form.emails.indexOf(email);
            if (index > -1) {
                state.form.emails.splice(index, 1);
            }
        },
        RESET_FORM(state) {
            state.form = {
                email_subject: '',
                email_to: '',
                emails: [],
                id: null,
            };
        },
        async SET_SCHEDULER_EDIT(
            state: {
                schedule: ScheduleEntity;
                report: ReportDataEntity;
            },
            params: { schedule: any; report: any }
        ) {
            try {
                if (!(state.schedule instanceof ScheduleEntity)) {
                    state.schedule = new ScheduleEntity();
                }

                if (params.schedule) {
                    await state.schedule.form.setSchedule(params.schedule);
                }

                if (params.report) {
                    const reportData = {
                        report_type: params.report.report_type,
                        start_date: params.report.start_date,
                        end_date: params.report.end_date,
                        type: params.report.type,
                        data_range: params.report.data_range,
                        dimensions: params.report.dimensions,
                        metrics: params.report.metrics,
                        filters: params.report.filters
                    };

                    if (state.report) {
                        await state.report.setReportData(reportData);
                    }
                }
            } catch (error) {
                console.error('Error en SET_SCHEDULER_EDIT:', error);
            }
        },
		SET_PENDING_NOTIFICATION(state) {
			state.pendingNotification = true;
		},
		CLEAR_PENDING_NOTIFICATION(state) {
			state.pendingNotification = null;
		},
    },
    getters:{
        getState: (state: { [x: string]: any }) => (key: string) => {
			return state[key];
		},
		storeSchedule(state: { schedule: ScheduleEntity; report: ReportDataEntity }) {
			try {
                state.schedule.form = state.schedule.form.mergeToReport(state.report);
				return state.schedule;
			} catch (error) {
				console.error('Error en storeSchedule getter:', error);
				return new ScheduleEntity();
			}
		},
		storeReport(state: {
			schedule: ScheduleEntity;
			report: ReportDataEntity;
		}) {
			return state.report;
		},
		storeResources(state: { resources: ResourceDataEntity }) {
			return state.resources;
		},
		storeResourceData(state: { resource_data: ReportResourceDataEntity }) {
			return state.resource_data;
		},
    },
    actions: {
        updateEmailSubject({ commit }, payload) {
            commit('SET_EMAIL_SUBJECT', payload);
        },
        updateEmailTo({ commit }, payload) {
            commit('SET_EMAIL_TO', payload);
        },
        appendEmailTo({ commit }, email: string) {
            commit('ADD_EMAIL', email);
        },
        deleteEmailTo({ commit }, email: string) {
            commit('REMOVE_EMAIL', email);
        },
		setPendingNotification({ commit }) {
			commit('SET_PENDING_NOTIFICATION');
		},
		clearPendingNotification({ commit }) {
			commit('CLEAR_PENDING_NOTIFICATION');
		},

		async paginatedMyReport({ commit }, params) {
			try {
				const response = await MyReportService.paginatedMyReport(
					params
				);
				commit("SET_MY_REPORT_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

        async getSchedulerType({}) {
			try {
				const response = await MyReportService.getSchedulerTypes();
				const result = resolveListParams(
					response,
					"extra",
					"description"
				);
				return await Promise.resolve(result);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async sendEmail(context, params: any){
			try {
				const result = await MyReportService.sendEmailMyReport(this.state.my_report.form);
				notificationService.notifySuccess(this, 
					{to: "",
                    title: i18n.t(`myReport.messages.title_success`),
                    message:  i18n.t(`myReport.messages.success_send_mail`)} as Notification);
                    context.commit('RESET_FORM');
                    return await Promise.resolve({});
                } catch (error) {
                    catchError(this, error);
				return await Promise.reject(error);
			}
		},

        async getData({}, params: any){
			try {
				const result = await MyReportService.getResourceScheduler(params);
                    return await Promise.resolve(result);
                } catch (error) {
                    catchError(this, error);
				return await Promise.reject(error);
			}
		},

        async getReport({}, params: any){
			try {
				const result = await MyReportService.getResourceReport(params);
                    return await Promise.resolve(result);
                } catch (error) {
                    catchError(this, error);
				return await Promise.reject(error);
			}
		},

        async getReportType({}, params: any){
			try {
				const result = await MyReportService.getResourceReportType();
                    return await Promise.resolve(result);
                } catch (error) {
                    catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async deleteMyReport({}, id){
			try {
				const result = await MyReportService.deleteMyReport(id);
				notificationService.notifySuccess(this, 
					{to: "",
						 title: i18n.t(`myReport.messages.title_success`),
						message:  i18n.t(`myReport.messages.success_delete`)} as Notification);
				return await Promise.resolve({});
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async sendNotify(
			{ rootState, dispatch }: any,
			payload: {
			  type: SnactType;
			  message: string;
			  messageParams: Record<any, any>;
			}
        ) {
			let snacktify: SnacktifyEntity = rootState.snacktify._snacktify;
			snacktify.setSnacktifyData({
                type: payload.type,
                message: payload.message,
                messageParams: payload.messageParams,
			});
		  
			await dispatch('my_report/setPendingNotification', payload);
        }
    }
};

export default MyReportModule;
