import {
    AddContentHtml5DataCreate,
    AddContentIframeTagDataCreate,
    AddContentImageDataCreate,
    AddContentJsTagDataCreate,
    AddContentMraidTagDataCreate,
    AddContentVastInlineDataCreate,
    AddContentVastWrapperDataCreate,
    AddonSettingDataCreate,
    AddonSettingVastInlineDataCreate,
    AddonSettingVastWrapperDataUpdate,
    AdvertiserDataCreate,
    AttributeDataCreate,
    AttributeVastWrapperDataCreate,
    AttributeVastInlineDataCreate,
    ExchangeOptionsHtml5DataCreate,
    ExchangeOptionsIframeTagDataCreate,
    ExchangeOptionsImageDataCreate,
    ExchangeOptionsJsTagDataCreate,
    ExchangeOptionsMraidTagDataCreate,
    ExchangeOptionsVastInlineDataCreate,
    ExchangeOptionsVastWrapperDataUpdate,
    VideoAsDuration,
    AddContentVastWrapperAudioDataCreate,
} from "@/interfaces/creative";
import { Html5DataCreate } from "@/interfaces/creativeBannerHtml5";
import { IframeTagDataCreate } from "@/interfaces/creativeBannerIframeTag";
import { ImageDataCreate } from "@/interfaces/creativeBannerImage";
import { JsTagDataCreate } from "@/interfaces/creativeBannerJsTag";

import { MraidTagDataCreate } from "@/interfaces/creativeBannerMraidTag";
import { VastInlineDataCreate } from "@/interfaces/creativeVideoVastInline";
import { VastWrapperDataCreate } from "@/interfaces/creativeVideoVastWrapper";
import { VastWrapperAudioDataCreate } from "@/interfaces/creativeAudioVastWrapper";
import moment from 'moment-timezone';
import { isEmpty, isNull, isUndefined } from "lodash";


export function valuesVideoAdDuration() {
    return [
        {
            header: "Durations"
        } as VideoAsDuration,
        {
            group: "Durations",
            value: "15 segundos",
            id: 15
        } as VideoAsDuration,
        {
            group: "Durations",
            value: "30 segundos",
            id: 30
        } as VideoAsDuration,
        {
            group: "Durations",
            value: "60 segundos",
            id: 60
        } as VideoAsDuration,
        {
            header: "Custom Durations"
        } as VideoAsDuration,
        {
            group: "Custom Durations",
            value: "Enter customizer duration",
            id: undefined
        } as VideoAsDuration,
    ]
}

export function fieldsCreative() {
    return [
        {
            creative_template_id: 1, // Banner Image
            fields: [
                'budget_type_id',
                'primary_asset_id',
                'click_url',
                'vendors',
                'landing_page_url',
                'appnexus_submit',
                'addons',
                'pixels',
                'scripts'
            ]
        },
        {
            creative_template_id: 3, // VAST InLine
            fields: [
                'video_template',
                'start_date',
                'end_date',
                'budget_type_id',
                'title',
                'click_url',
                'video_duration',
                'primary_asset_id',
                'secondary_asset_id',
                'companion_html',
                'companion_size_id',
                'skippable',
                'skip_offset',
                'vendors',
                'landing_page_url',
                'appnexus_submit',
                'addons',
                'pixels',
                'scripts',
                'click_trackers',
                'vast_events',
                'progress_events',
                'creative_atributes',
                'moat_inapp_viewability',
                'moat_web_viewability'
            ]
        },
        {
            creative_template_id: 4, // Banner Js Tag
            fields: [
                'creative_atributes',
                'start_date',
                'end_date',
                'tag',
                'creative_rule_id',
                'mime_type_id',
                'expandable_type_id',
                'expandable_directions',
                'responsive',
                'in_banner_video_id',
                'vendors',
                'landing_page_url',
                'appnexus_submit',
                'thumbnail_id',
                'addons',
                'pixels',
                'scripts',
                'size_id'
            ]
        },
        {
            creative_template_id: 5, // Banner Iframe Tag
            fields: [
                'creative_atributes',
                'start_date',
                'end_date',
                'tag',
                'creative_rule_id',
                'size_id',
                'mime_type_id',
                'expandable_type_id',
                'expandable_directions',
                'responsive',
                'in_banner_video_id',
                'vendors',
                'landing_page_url',
                'appnexus_submit',
                'thumbnail_id',
                'addons',
                'pixels',
                'scripts'
            ]
        },
        {
            creative_template_id: 6, // Video Wrapper
            fields: [
                'video_mime_types',
                'btn_detect',
                'video_template',
                'tag_video',
                'video_duration',
                'skippable',
                'moat_inapp_viewability',
                'audio_api',
                'video_api',
                'video_bid_rate',
                'vendors',
                'landing_page_url',
                'appnexus_submit',
                'addons',
                'pixels',
                'scripts',
                'click_trackers',
                'vast_events',
                'progress_events',
                'creative_atributes'
            ]
        },
        {
            creative_template_id: 13, // Banner MRAID Tag
            fields: [
                'creative_atributes',
                'start_date',
                'end_date',
                'tag',
                'creative_rule_id',
                'size_id',
                'mime_type_id',
                'expandable_type_id',
                'expandable_directions',
                'responsive',
                'in_banner_video_id',
                'vendors',
                'landing_page_url',
                'appnexus_submit',
                'thumbnail_id',
                'addons',
                'pixels',
                'scripts',
				'mraid_playable'
            ]
        },
        {
            creative_template_id: 14, // Native Image App Install
            fields: []
        },
        {
            creative_template_id: 15, // Native Video App Install
            fields: []
        },
        {
            creative_template_id: 16, // Native Image Content
            fields: []
        },
        {
            creative_template_id: 17, // Native Video Content
            fields: []
        },
        {
            creative_template_id: 18, // Native Video App Install (VAST/VPAID Wrapper)
            fields: []
        },
        {
            creative_template_id: 19, // Native Video Content (VAST/VPAID Wrapper)
            fields: []
        },
        {
            creative_template_id: 20, // VAST Wrapper with MOAT Viewability
            fields: [
                'video_mime_types',
                'btn_detect',
                'video_template',
                'video_duration',
                'skippable',
                'video_api',
                'video_bid_rate',
                'vendors',
                'landing_page_url',
                'appnexus_submit',
                'tag_video',
                'addons',
                'pixels',
                'scripts',
                'click_trackers',
                'vast_events',
                'progress_events',
                'creative_atributes'
            ]
        },
        {
            creative_template_id: 21, // HTML5 Creative
            fields: [
                'creative_atributes',
                'start_date',
                'end_date',
                //'tag',
                //'creative_rule_id',
                //'budget_type_id',
                'primary_asset_id',
                'size_id',
                'mime_type_id',
                'expandable_type_id',
                'expandable_directions',
                'responsive',
                'in_banner_video_id',
                'vendors',
                'landing_page_url',
                'appnexus_submit',
                'thumbnail_id',
                'addons',
                'pixels',
                'scripts'
            ]
        },
        {
            creative_template_id: 22, // App Promo Icon
            fields: []
        },
        {
            creative_template_id: 23, // Audio VAST InLine
            fields: [
                'audio_type_id',
                'audio_template',
                'title',
                'click_url_audio',
                'primary_asset_id',
                'creative_atributes',
                'skippable',
                'skip_offset',
                'companion_html',
                'companion_size_id',
                'skip_time',
                'vendors',
                'landing_page_url',
                'pixels',
                'scripts',
                'click_trackers',
                'vast_events',
                'progress_events',
            ]
        },
        {
            creative_template_id: 24, // Audio VAST wrapper
            fields: [
                'tag_video',
                'video_template',
                'creative_atributes',
                'audio_duration',
                'audio_mime_types',
                'skip_offset',
                'audio_bid_rate',
                'skippable',
                'vendors',
                'landing_page_url',
                'pixels',
                'click_trackers',
                'vast_events',
                'progress_events'
            ]
        }
    ]



}

export function fieldsCreativeRequestCampaign(){
    return [
        {
            creative_extra: "crea_display", // type display
            fields: [
                'name',
                'type_creative',
                'size',
                'imagen'
            ]
        },
        {
            creative_extra: "crea_native", // type native
            fields: [
                'name',
                'type_creative',
                'title_text',
                'body_text',
                'call_to_action',
                'icon',
                'imagen'
            ]
        },
        {
            creative_extra: "crea_video", // type video
            fields: [
                'name',
                'type_creative',
                'aspect_ratio',
                'video',
                'imagen'
            ]
        },
        {
            creative_extra: "crea_audio", // type audio
            fields: [
                'name',
                'type_creative',
                'aspect_ratio',
                'video',
                'imagen',
                'audio',
            ]
        },
    ]
}

export function hasFieldByTemplateId(creative_template_id: number, input: string) {
    const fields = fieldsCreative().find(template => template.creative_template_id === creative_template_id)?.fields;
    return fields && fields.includes(input);
}

export function hasFieldByTemplateIdAssociatedCreative(creative_extra: string, input: string) {
    const fields = fieldsCreativeRequestCampaign().find(template => template.creative_extra === creative_extra)?.fields;
    return fields && fields.includes(input);
}

export async function getCreativeParsedByType(params: { data: any; edit: boolean; }) {
	return {
		1: parseDataCreativeImage(params),
		3: parseDataCreativeVastInline(params),
		4: parseDataCreativeJsTag(params),
		5: parseDataCreativeIframeTag(params),
		6: parseDataCreativeVastAndVPAIDWrapper(params),
		13: parseDataCreativeMraidTag(params),
		20: parseDataCreativeVASTWrapperWithMOATViewability(params),
		21: parseDataCreativeHtml5(params),
		23: parseDataCreativeAudioVastInline(params),
		24: parseDataCreativeVASTWrapperAudio(params),
	}
}

export function parseDataCreativeImage(params: { data: ImageDataCreate; edit: boolean; }) {
    return {
        id: params.edit ? Number(params.data.id) : undefined,
        creative_type_id: params.data.creative_type_id,
        alternative_id: params.data.alternative_id,
        creative_template_id: params.data.creative_template_id,
        start_date: params.data.start_date ? moment(String(params.data.start_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        end_date: params.data.end_date ? moment(String(params.data.end_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        name: params.data.name,
        secure: params.data.secure,
        active: params.data.active,
        creative_advertiser: {
            advertiser_id: params.data.creative_advertiser.advertiser_id,
            category_id: params.data.creative_advertiser.category_id,
            domain: params.data.creative_advertiser.domain,
            app_bundle: params.data.creative_advertiser.app_bundle,
        } as AdvertiserDataCreate,
        creative_ad_content: {
            primary_asset_id: params.data.creative_ad_content.primary_asset_id,
            click_url: params.data.creative_ad_content.click_url
        } as AddContentImageDataCreate,
        creative_exchange_options: {
            vendors: params.data.creative_exchange_options.vendors,
            landing_page_url: params.data.creative_exchange_options.landing_page_url,
            appnexus_submit: params.data.creative_exchange_options.appnexus_submit
        } as ExchangeOptionsImageDataCreate,
        creative_addon_settings: {
            addons: params.data.creative_addon_settings.addons,
            pixels: params.data.creative_addon_settings.pixels,
            scripts: params.data.creative_addon_settings.scripts
        } as AddonSettingDataCreate
    } as ImageDataCreate
}

export function parseDataCreativeJsTag(params: { data: JsTagDataCreate; edit: boolean; }) {
    return {
        id: params.edit ? Number(params.data.id) : undefined,
        creative_type_id: params.data.creative_type_id,
        creative_template_id: params.data.creative_template_id,
        start_date: params.data.start_date ? moment(String(params.data.start_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        end_date: params.data.end_date ? moment(String(params.data.end_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        alternative_id: params.data.alternative_id,
        name: params.data.name,
        secure: params.data.secure,
        active: params.data.active,
        creative_advertiser: {
            advertiser_id: params.data.creative_advertiser.advertiser_id,
            category_id: params.data.creative_advertiser.category_id,
            domain: params.data.creative_advertiser.domain,
            app_bundle: params.data.creative_advertiser.app_bundle
        } as AdvertiserDataCreate,
        creative_ad_content: {
            creative_rule_id: params.data.creative_ad_content.creative_rule_id,
            tag: params.data.creative_ad_content.tag
        } as AddContentJsTagDataCreate,
        creative_attributes: {
            width: params.data.creative_attributes.width,
            height: params.data.creative_attributes.height,
            expandable_directions: params.data.creative_attributes.expandable_directions,
            expandable_type_id: params.data.creative_attributes.expandable_type_id,
            in_banner_video_id: params.data.creative_attributes.in_banner_video_id,
            mime_type_id: params.data.creative_attributes.mime_type_id,
            responsive: params.data.creative_attributes.responsive ? 1 : 0
        } as AttributeDataCreate,
        creative_exchange_options: {
            vendors: params.data.creative_exchange_options.vendors,
            landing_page_url: params.data.creative_exchange_options.landing_page_url,
            appnexus_submit: params.data.creative_exchange_options.appnexus_submit,
            thumbnail_id: params.data.creative_exchange_options.thumbnail_id,
        } as ExchangeOptionsJsTagDataCreate,
        creative_addon_settings: {
            addons: params.data.creative_addon_settings.addons,
            pixels: params.data.creative_addon_settings.pixels,
            scripts: params.data.creative_addon_settings.scripts
        } as AddonSettingDataCreate
    } as JsTagDataCreate
}

export function parseDataCreativeIframeTag(params: { data: IframeTagDataCreate; edit: boolean; }) {
    return {
        id: params.edit ? Number(params.data.id) : undefined,
        creative_type_id: params.data.creative_type_id,
        creative_template_id: params.data.creative_template_id,
        start_date: params.data.start_date ? moment(String(params.data.start_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        end_date: params.data.end_date ? moment(String(params.data.end_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        alternative_id: params.data.alternative_id,
        name: params.data.name,
        secure: params.data.secure,
        active: params.data.active,
        creative_advertiser: {
            advertiser_id: params.data.creative_advertiser.advertiser_id,
            category_id: params.data.creative_advertiser.category_id,
            domain: params.data.creative_advertiser.domain,
            app_bundle: params.data.creative_advertiser.app_bundle
        } as AdvertiserDataCreate,
        creative_ad_content: {
            creative_rule_id: params.data.creative_ad_content.creative_rule_id,
            tag: params.data.creative_ad_content.tag
        } as AddContentIframeTagDataCreate,
        creative_attributes: {
            width: params.data.creative_attributes.width,
            height: params.data.creative_attributes.height,
            expandable_directions: params.data.creative_attributes.expandable_directions,
            expandable_type_id: params.data.creative_attributes.expandable_type_id,
            in_banner_video_id: params.data.creative_attributes.in_banner_video_id,
            mime_type_id: params.data.creative_attributes.mime_type_id,
            responsive: params.data.creative_attributes.responsive ? 1 : 0
        } as AttributeDataCreate,
        creative_exchange_options: {
            vendors: params.data.creative_exchange_options.vendors,
            landing_page_url: params.data.creative_exchange_options.landing_page_url,
            appnexus_submit: params.data.creative_exchange_options.appnexus_submit,
            thumbnail_id: params.data.creative_exchange_options.thumbnail_id,
        } as ExchangeOptionsIframeTagDataCreate,
        creative_addon_settings: {
            addons: params.data.creative_addon_settings.addons,
            pixels: params.data.creative_addon_settings.pixels,
            scripts: params.data.creative_addon_settings.scripts
        } as AddonSettingDataCreate
    } as IframeTagDataCreate
}

export function parseDataCreativeMraidTag(params: { data: MraidTagDataCreate; edit: boolean; }) {
    return {
        id: params.edit ? Number(params.data.id) : undefined,
        creative_type_id: params.data.creative_type_id,
        creative_template_id: params.data.creative_template_id,
        start_date: params.data.start_date ? moment(String(params.data.start_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        end_date: params.data.end_date ? moment(String(params.data.end_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        alternative_id: params.data.alternative_id,
        name: params.data.name,
        secure: params.data.secure,
        active: params.data.active,
        creative_advertiser: {
            advertiser_id: params.data.creative_advertiser.advertiser_id,
            category_id: params.data.creative_advertiser.category_id,
            domain: params.data.creative_advertiser.domain,
            app_bundle: params.data.creative_advertiser.app_bundle
        } as AdvertiserDataCreate,
        creative_ad_content: {
            creative_rule_id: params.data.creative_ad_content.creative_rule_id,
            tag: params.data.creative_ad_content.tag,
        } as AddContentMraidTagDataCreate,
        creative_attributes: {
            width: params.data.creative_attributes.width,
            height: params.data.creative_attributes.height,
            expandable_directions: params.data.creative_attributes.expandable_directions,
            expandable_type_id: params.data.creative_attributes.expandable_type_id,
            in_banner_video_id: params.data.creative_attributes.in_banner_video_id,
            mime_type_id: params.data.creative_attributes.mime_type_id,
            responsive: params.data.creative_attributes.responsive ? 1 : 0,
			mraid_playable: params.data.creative_attributes.mraid_playable ? 1 : 0
        } as AttributeDataCreate,
        creative_exchange_options: {
            vendors: params.data.creative_exchange_options.vendors,
            landing_page_url: params.data.creative_exchange_options.landing_page_url,
            appnexus_submit: params.data.creative_exchange_options.appnexus_submit,
            thumbnail_id: params.data.creative_exchange_options.thumbnail_id
        } as ExchangeOptionsMraidTagDataCreate,
        creative_addon_settings: {
            addons: params.data.creative_addon_settings.addons,
            pixels: params.data.creative_addon_settings.pixels,
            scripts: params.data.creative_addon_settings.scripts
        } as AddonSettingDataCreate
    } as MraidTagDataCreate
}

export function parseDataCreativeVastInline(params: { data: VastInlineDataCreate; edit: boolean; }) {
    return {
        id: params.edit ? Number(params.data.id) : undefined,
        creative_type_id: params.data.creative_type_id,
        creative_template_id: params.data.creative_template_id,
        start_date: params.data.start_date ? moment(String(params.data.start_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        end_date: params.data.end_date ? moment(String(params.data.end_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        alternative_id: params.data.alternative_id,
        name: params.data.name,
        secure: params.data.secure,
        active: params.data.active,
        creative_advertiser: {
            advertiser_id: params.data.creative_advertiser.advertiser_id,
            category_id: params.data.creative_advertiser.category_id,
            domain: params.data.creative_advertiser.domain,
            app_bundle: params.data.creative_advertiser.app_bundle
        } as AdvertiserDataCreate,
        creative_ad_content: {
            title: isEmpty(params.data.creative_ad_content.title)
                    || isNull(params.data.creative_ad_content.title)
                    ? null
                    : params.data.creative_ad_content.title,
            click_url: params.data.creative_ad_content.click_url,
            companion_html: isEmpty(params.data.creative_ad_content.companion_html)
                            || isNull(params.data.creative_ad_content.companion_html)
                            ? null
                            : params.data.creative_ad_content.companion_html,
            companion_size_id: isEmpty(params.data.creative_ad_content.companion_html)
                                || isNull(params.data.creative_ad_content.companion_html)
                                ? null
                                : params.data.creative_ad_content.companion_size_id,
            primary_asset_id: params.data.creative_ad_content.primary_asset_id,
            video_duration: params.data.creative_ad_content.video_duration,
            secondary_asset_id: params.data.creative_ad_content.secondary_asset_id,
        } as AddContentVastInlineDataCreate,
        creative_attributes: {
            width: params.data.creative_attributes.width,
            height: params.data.creative_attributes.height,
            skippable: params.data.creative_attributes.skippable,
            //skip_offset: isNull(params.data.creative_attributes.skip_offset) ? "" : params.data.creative_attributes.skip_offset,
            moat_web_viewability: params.data.creative_attributes.moat_web_viewability ? 1 : 0,
            moat_inapp_viewability: params.data.creative_attributes.moat_inapp_viewability ? 1 : 0,
        } as AttributeVastInlineDataCreate,
        creative_exchange_options: {
            vendors: params.data.creative_exchange_options.vendors,
            //landing_page_url: params.data.creative_exchange_options.landing_page_url,
            landing_page_url: null,
            appnexus_submit: params.data.creative_exchange_options.appnexus_submit
        } as ExchangeOptionsVastInlineDataCreate,
        creative_addon_settings: {
            addons: params.data.creative_addon_settings.addons,
            pixels: params.data.creative_addon_settings.pixels,
            scripts: params.data.creative_addon_settings.scripts,
            click_trackers: params.data.creative_addon_settings.click_trackers,
            vast_events: params.data.creative_addon_settings.vast_events,
            progress_events: params.data.creative_addon_settings.progress_events
        } as AddonSettingVastInlineDataCreate
    } as VastInlineDataCreate
}

export function parseDataCreativeAudioVastInline(params: { data: VastInlineDataCreate; edit: boolean; }) {
    return {
        id: params.edit ? Number(params.data.id) : undefined,
        creative_type_id: params.data.creative_type_id,
        creative_template_id: params.data.creative_template_id,
        start_date: params.data.start_date ? moment(String(params.data.start_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        end_date: params.data.end_date ? moment(String(params.data.end_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        alternative_id: params.data.alternative_id,
        name: params.data.name,
        secure: params.data.secure,
        active: params.data.active,
        creative_advertiser: {
            advertiser_id: params.data.creative_advertiser.advertiser_id,
            category_id: params.data.creative_advertiser.category_id,
            domain: params.data.creative_advertiser.domain
        } as AdvertiserDataCreate,
        creative_ad_content: {
            title: isEmpty(params.data.creative_ad_content.title)
                    || isNull(params.data.creative_ad_content.title)
                    ? null
                    : params.data.creative_ad_content.title,
            click_url: params.data.creative_ad_content.click_url,
            companion_html: isEmpty(params.data.creative_ad_content.companion_html)
                            || isNull(params.data.creative_ad_content.companion_html)
                            ? null
                            : params.data.creative_ad_content.companion_html,
            companion_size_id: isEmpty(params.data.creative_ad_content.companion_html)
                                || isNull(params.data.creative_ad_content.companion_html)
                                ? null
                                : params.data.creative_ad_content.companion_size_id,
            primary_asset_id: params.data.creative_ad_content.primary_asset_id,
            secondary_asset_id: params.data.creative_ad_content.secondary_asset_id,
        } as AddContentVastInlineDataCreate,
        creative_attributes: {
            width: params.data.creative_attributes.width,
            height: params.data.creative_attributes.height,
            skippable: params.data.creative_attributes.skippable,
            skip_offset: isNull(params.data.creative_attributes.skip_offset) ? "" : params.data.creative_attributes.skip_offset,
            moat_web_viewability: params.data.creative_attributes.moat_web_viewability ? 1 : 0,
            moat_inapp_viewability: params.data.creative_attributes.moat_inapp_viewability ? 1 : 0,
            mraid_playable: false,
        } as AttributeVastInlineDataCreate,
        creative_exchange_options: {
            vendors: params.data.creative_exchange_options.vendors,
            landing_page_url: params.data.creative_exchange_options.landing_page_url,
            appnexus_submit: params.data.creative_exchange_options.appnexus_submit
        } as ExchangeOptionsVastInlineDataCreate,
        creative_addon_settings: {
            addons: params.data.creative_addon_settings.addons,
            pixels: params.data.creative_addon_settings.pixels,
            scripts: params.data.creative_addon_settings.scripts,
            click_trackers: params.data.creative_addon_settings.click_trackers,
            vast_events: params.data.creative_addon_settings.vast_events,
            progress_events: params.data.creative_addon_settings.progress_events
        } as AddonSettingVastInlineDataCreate
    } as VastInlineDataCreate
}

export function parseDataCreativeHtml5(params: { data: Html5DataCreate; edit: boolean; }) {
    return {
        id: params.edit ? Number(params.data.id) : undefined,
        creative_type_id: params.data.creative_type_id,
        creative_template_id: params.data.creative_template_id,
        start_date: params.data.start_date ? moment(String(params.data.start_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        end_date: params.data.end_date ? moment(String(params.data.end_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        alternative_id: params.data.alternative_id,
        name: params.data.name,
        secure: params.data.secure,
        active: params.data.active,
        creative_advertiser: {
            advertiser_id: params.data.creative_advertiser.advertiser_id,
            category_id: params.data.creative_advertiser.category_id,
            domain: params.data.creative_advertiser.domain,
            app_bundle: params.data.creative_advertiser.app_bundle
        } as AdvertiserDataCreate,
        creative_ad_content: {
            primary_asset_id: params.data.creative_ad_content.primary_asset_id,
        } as AddContentHtml5DataCreate,
        creative_attributes: {
            width: params.data.creative_attributes.width,
            height: params.data.creative_attributes.height,
            expandable_directions: params.data.creative_attributes.expandable_directions,
            expandable_type_id: params.data.creative_attributes.expandable_type_id,
            in_banner_video_id: params.data.creative_attributes.in_banner_video_id,
            mime_type_id: params.data.creative_attributes.mime_type_id,
            responsive: params.data.creative_attributes.responsive ? 1 : 0,
        } as AttributeDataCreate,
        creative_exchange_options: {
            thumbnail_id: params.data.creative_exchange_options.thumbnail_id,
            vendors: params.data.creative_exchange_options.vendors,
            landing_page_url: params.data.creative_exchange_options.landing_page_url,
            appnexus_submit: params.data.creative_exchange_options.appnexus_submit
        } as ExchangeOptionsHtml5DataCreate,
        creative_addon_settings: {
            addons: params.data.creative_addon_settings.addons,
            pixels: params.data.creative_addon_settings.pixels,
            scripts: params.data.creative_addon_settings.scripts
        } as AddonSettingDataCreate
    } as Html5DataCreate
}

export function parseDataCreativeVastAndVPAIDWrapper(params: { data: VastWrapperDataCreate; edit: boolean; }) {
    return {
        id: params.edit ? Number(params.data.id) : undefined,
        creative_type_id: params.data.creative_type_id,
        creative_template_id: params.data.creative_template_id,
        start_date: params.data.start_date ? moment(String(params.data.start_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        end_date: params.data.end_date ? moment(String(params.data.end_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        alternative_id: params.data.alternative_id,
        name: params.data.name,
        secure: params.data.secure,
        active: params.data.active,
        creative_advertiser: {
            advertiser_id: params.data.creative_advertiser.advertiser_id,
            category_id: params.data.creative_advertiser.category_id,
            domain: params.data.creative_advertiser.domain,
            app_bundle: params.data.creative_advertiser.app_bundle
        } as AdvertiserDataCreate,
        creative_ad_content: {
            tag: params.data.creative_ad_content.tag,
            video_duration: params.data.creative_ad_content.video_duration,
        } as AddContentVastWrapperDataCreate,
        creative_attributes: {
            width: params.data.creative_attributes.width,
            height: params.data.creative_attributes.height,
            skippable: params.data.creative_attributes.skippable ? 1 : 0,
            moat_inapp_viewability: params.data.creative_attributes.moat_inapp_viewability ? 1 : 0,
            video_api_id: isUndefined(params.data.creative_attributes.video_api_id) ? null : params.data.creative_attributes.video_api_id,
            video_bit_rates: params.data.creative_attributes.video_bit_rates,
            video_mime_types: params.data.creative_attributes.video_mime_types,
            audio_mime_types: params.data.creative_attributes.audio_mime_types
        } as AttributeVastWrapperDataCreate,
        creative_exchange_options: {
            vendors: params.data.creative_exchange_options.vendors,
            landing_page_url: params.data.creative_exchange_options.landing_page_url,
            appnexus_submit: params.data.creative_exchange_options.appnexus_submit
        } as ExchangeOptionsVastWrapperDataUpdate,
        creative_addon_settings: {
            addons: params.data.creative_addon_settings.addons,
            pixels: params.data.creative_addon_settings.pixels,
            scripts: params.data.creative_addon_settings.scripts,
            click_trackers: params.data.creative_addon_settings.click_trackers,
            vast_events: params.data.creative_addon_settings.vast_events,
            progress_events: params.data.creative_addon_settings.progress_events
        } as AddonSettingVastWrapperDataUpdate
    } as VastWrapperDataCreate
}

export function parseDataCreativeVASTWrapperWithMOATViewability(params: { data: VastWrapperDataCreate; edit: boolean; }) {
    return {
        id: params.edit ? Number(params.data.id) : undefined,
        creative_type_id: params.data.creative_type_id,
        creative_template_id: params.data.creative_template_id,
        start_date: params.data.start_date ? moment(String(params.data.start_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        end_date: params.data.end_date ? moment(String(params.data.end_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        alternative_id: params.data.alternative_id,
        name: params.data.name,
        secure: params.data.secure,
        active: params.data.active,
        creative_advertiser: {
            advertiser_id: params.data.creative_advertiser.advertiser_id,
            category_id: params.data.creative_advertiser.category_id,
            domain: params.data.creative_advertiser.domain,
            app_bundle: params.data.creative_advertiser.app_bundle
        } as AdvertiserDataCreate,
        creative_ad_content: {
            tag: params.data.creative_ad_content.tag,
            video_duration: params.data.creative_ad_content.video_duration,
        } as AddContentVastWrapperDataCreate,
        creative_attributes: {
            width: params.data.creative_attributes.width,
            height: params.data.creative_attributes.height,
            skippable: params.data.creative_attributes.skippable ? 1 : 0,
            video_api_id: params.data.creative_attributes.video_api_id,
            video_bit_rates: params.data.creative_attributes.video_bit_rates,
            video_mime_types: params.data.creative_attributes.video_mime_types,
        } as AttributeVastWrapperDataCreate,
        creative_exchange_options: {
            vendors: params.data.creative_exchange_options.vendors,
            landing_page_url: params.data.creative_exchange_options.landing_page_url,
            appnexus_submit: params.data.creative_exchange_options.appnexus_submit
        } as ExchangeOptionsVastWrapperDataUpdate,
        creative_addon_settings: {
            addons: params.data.creative_addon_settings.addons,
            pixels: params.data.creative_addon_settings.pixels,
            scripts: params.data.creative_addon_settings.scripts,
            click_trackers: params.data.creative_addon_settings.click_trackers,
            vast_events: params.data.creative_addon_settings.vast_events,
            progress_events: params.data.creative_addon_settings.progress_events
        } as AddonSettingVastWrapperDataUpdate
    } as VastWrapperDataCreate
}

export function parseDataCreativeVASTWrapperAudio(params: { data: VastWrapperAudioDataCreate; edit: boolean; }) {
    return {
        id: params.edit ? Number(params.data.id) : undefined,
        creative_type_id: params.data.creative_type_id,
        creative_template_id: params.data.creative_template_id,
        start_date: params.data.start_date ? moment(String(params.data.start_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        end_date: params.data.end_date ? moment(String(params.data.end_date)).format('YYYY-MM-DD HH:mm:ss') : null,
        alternative_id: params.data.alternative_id,
        name: params.data.name,
        secure: params.data.secure,
        active: params.data.active,
        creative_advertiser: {
            advertiser_id: params.data.creative_advertiser.advertiser_id,
            category_id: params.data.creative_advertiser.category_id,
            domain: params.data.creative_advertiser.domain,
            app_bundle: params.data.creative_advertiser.app_bundle
        } as AdvertiserDataCreate,
        creative_ad_content: {
            tag: params.data.creative_ad_content.tag,
            audio_duration: params.data.creative_ad_content.audio_duration,
        } as AddContentVastWrapperAudioDataCreate,
        creative_attributes: {
            skippable: params.data.creative_attributes.skippable ? 1 : 0,
            audio_mime_types: params.data.creative_attributes.audio_mime_types,
            audio_bit_rates: params.data.creative_attributes.audio_bit_rates,

        } as AttributeVastWrapperDataCreate,
        creative_exchange_options: {
            vendors: params.data.creative_exchange_options.vendors,
            landing_page_url: params.data.creative_exchange_options.landing_page_url,
            appnexus_submit: params.data.creative_exchange_options.appnexus_submit
        } as ExchangeOptionsVastWrapperDataUpdate,
        creative_addon_settings: {
            addons: params.data.creative_addon_settings.addons,
            pixels: params.data.creative_addon_settings.pixels,
            scripts: params.data.creative_addon_settings.scripts,
            click_trackers: params.data.creative_addon_settings.click_trackers,
            vast_events: params.data.creative_addon_settings.vast_events,
            progress_events: params.data.creative_addon_settings.progress_events
        } as AddonSettingVastWrapperDataUpdate
    } as VastWrapperDataCreate
}
