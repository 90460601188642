import ReportService from "@/services/report-service";
import { resolveListParams } from "@/utils/resolveObjectArray";
import { catchError } from "@/store/Util";
import { ResultPaginate } from "@/interfaces/paginated";
import notificationService from "@/services/notification-service";
import { MessageTypes, Notification } from "@/interfaces/proccess";
import { delayAttempt, isWillBeRetried } from "@/utils/report";
import { sleep } from "@/utils/convert";
import {
	EnumReportType,
	EnumStatus,
	ReportGetParam,
	ResultDataEntity,
	ReportFilterSP
} from "@/interfaces/report";
import { ScheduleCreate } from "@/interfaces/reports/v2/scheduler";
import { isEqual } from "lodash";
import i18n from "@/plugins/i18n";

export const ReportModule = {
	namespaced: true,
	state: () => ({
		advertisers: [] as any[],
		line_items: [] as any[],
		creatives: [] as any[],
		campaigns: [] as any[],
		result_paginate: {} as ResultPaginate,
		result_onDemand_paginate: {} as ResultPaginate,
		resultReport: new ResultDataEntity(),
	}),
	mutations: {
		SET_STATE(state, params: { key: string; value: any }) {
			state[params.key] = params.value;
		},
		SET_RESULT_PAGINATED(
			state: { result_paginate: ResultPaginate },
			_result_paginate: ResultPaginate = {} as ResultPaginate
		) {
			const statePaginate = state.result_paginate;			
			const _isEqual = isEqual(statePaginate, _result_paginate);
			if(_isEqual) return;
			state.result_paginate = _result_paginate;
		},
		SET_REPORT_ON_DEMAND_PAGINATED(
			state,
			_result_paginated: ResultPaginate = {} as ResultPaginate
		) {
			state.result_onDemand_paginate = _result_paginated;
		},
	},
	getters: {
		getState: (state) => (key: string) => {
			return state[key];
		},
		result_paginate(state): ResultPaginate {
			return state.result_paginate;
		},
		result_onDemand_paginate(state): ResultPaginate {
			return state.result_onDemand_paginate;
		},
		result_myReport_paginate(state): ResultPaginate {
			return state.result_myReport_paginate;
		},
	},
	actions: {
		setState({ commit }, params: { key: string; value: any }) {
			return new Promise((resolve) => {
				commit("SET_STATE", params);
				resolve(params);
			});
		},

		async search({ commit }, params) {
			try {
				const response = await ReportService.search(params);
				const result: Array<any> = params?.return_object
					? response
					: resolveListParams(
							response,
							params.custom_key,
							params.custom_value
					);
				return await Promise.resolve(result);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async fields(
			{ commit },
			params: {
				report_type: string;
				field_type: string;
				type: string;
				custom_key?: string;
				custom_value?: string;
				return_object?: Boolean;
			}
		) {
			try {
				const response = await ReportService.fields(params);
				const result: Array<any> = params?.return_object
					? response
					: resolveListParams(
							response,
							params.custom_key,
							params.custom_value
					);
				return await Promise.resolve(result);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async run({ commit }, params) {
			try {
				const response = await ReportService.run({
					type: "run",
					report: params,
				});
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async postData({}, params: { type: string; payload: any }) {
			try {
				const result = await ReportService.postData(
					params.type,
					params.payload
				);
				return await Promise.resolve(result);
			} catch (error) {
				console.error("ReportModule::download", { error });
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async postDataService({}, params: { type: string; payload: any, typeFile: EnumReportType }) {
			try {
				const result = await ReportService.postDataService(
					params.type,
					params.payload,
					params.typeFile
				);
				return await Promise.resolve(result);
			} catch (error) {
				console.error("ReportModule::download", { error });
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async attemptGetReport({}, payload: ReportGetParam) {
			let willBeRetried = true;
			let attempt = 1;

			while (willBeRetried) {
				try {
					// Obtener datos desde la API
					const result = await ReportService.attemptGetReport(
						payload
					);

					let notification: Notification = {
						show: false,
						title: MessageTypes.TITLE_SUCCESS,
						message: "",
						btn_text: MessageTypes.CONTINUE,
						type: MessageTypes.SUCCESS,
					};

					if (result.status === EnumStatus.ERROR) {
						notification = {
							...notification,
							show: true,
							type: MessageTypes.ERROR,
							title: MessageTypes.TITLE_FAILED,
							message: "report.messages.error",
						};

						await notificationService.setCustomNotification(
							this,
							notification
						);

						return Promise.resolve(EnumStatus.ERROR);
					}

					if (result.status === EnumStatus.EMPTY) {
						notification = {
							...notification,
							show: true,
							type: MessageTypes.SUCCESS,
							title: MessageTypes.TITLE_SUCCESS,
							message: "report.messages.empty",
						};

						await notificationService.setCustomNotification(
							this,
							notification
						);

						return Promise.resolve(EnumStatus.EMPTY);
					}

					// si es csv | xls
					if (
						[EnumReportType.CSV, EnumReportType.XLS].includes(
							result.type
						)
					) {
						return Promise.resolve(EnumStatus.SUCCESS);
					}

					willBeRetried = await isWillBeRetried(result);

					if (!willBeRetried) {
						return Promise.resolve(EnumStatus.SUCCESS);
					}

					attempt++;
					await sleep(delayAttempt); // Tiempo de espera para reintentar
				} catch (error) {
					console.error("while::attemptGetReport", { error });
					willBeRetried = false;
					catchError(this, error);
					return Promise.reject(undefined);
				}
			}
		},

		async downloadReport(
			{},
			payload: {
				data: string;
				extension: EnumReportType.CSV | EnumReportType.XLS;
			}
		) {
			return ReportService.downloadReport(
				payload.data,
				payload.extension
			);
		},

		/**
		 * Preparar los parametros para obtener el binario de csv|xls|data report
		 * @param params
		 * @returns
		 */
		async prepareConfigReportAttempt(
			{},
			params: { result: ResultDataEntity; type: EnumReportType }
		) {
			return ReportService.prepareAttemptReport(
				params.result,
				params.type
			);
		},

		async download({}, params: { type: string; payload: any }) {
			try {
				const result = await ReportService.download(params);
				const hasResult = String(result) && result !== "Sin datos";
				return await Promise.resolve({ download: hasResult });
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		/**
		 * @deprecated The method should not be used
		 */
		async getDimMet(
			{ commit },
			params: {
				report_type: string;
				field_type: string;
			}
		) {
			try {
				const response = await ReportService.getDimMet(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getDimensionAndMetricSP({ commit }, params: {report_type: string}) {
			try {
				const response = await ReportService.getDimensionAndMetricsSP(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getAllReportType({}) {
			try {
				const response = await ReportService.getAllReportType();
				const result = resolveListParams(
					response,
					"extra",
					"description"
				);
				return await Promise.resolve(result);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getSchedulerTypes({}) {
			try {
				const response = await ReportService.getSchedulerTypes();
				const result = resolveListParams(
					response,
					"extra",
					"description"
				);
				return await Promise.resolve(result);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async createSchedulerReport({}, params: ScheduleCreate) {
			try {
				const response = await ReportService.createSchedulerReport(
					params
				);
				notificationService.notifySuccess(this, {
					to: "",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async editSchedulerReport({}, params: {
			data: any;
			id: number;
		}) {
			try {
				const response = await ReportService.update(
					params.data,
					params.id
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async getSchedulerPaginated({ commit }, params) {
			try {
				const response = await ReportService.getSchedulerPaginated(
					params
				);
				commit("SET_RESULT_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async paginatedReportOnDemand({ commit }, params) {
			try {
				const response = await ReportService.paginatedReportOnDemand(
					params
				);
				commit("SET_REPORT_ON_DEMAND_PAGINATED", response);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async fetchCountryStoreAttribution({ commit }, params) {
			try {
				const response =
					await ReportService.fetchCountryStoreAttribution(params);
				return await Promise.resolve(
					resolveListParams(
						response,
						params.custom_key,
						params.custom_value
					)
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async fetchCountryCampaign({ commit }, params) {
			try {
				const response = await ReportService.fetchCountryCampaign(
					params
				);
				return await Promise.resolve(
					resolveListParams(
						response,
						params.custom_key,
						params.custom_value
					)
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async fetchListPois({ commit }, params) {
			try {
				const response = await ReportService.fetchListPois(params);
				return await Promise.resolve(
					resolveListParams(
						response,
						params.custom_key,
						params.custom_value
					)
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async fetchCampaign({ commit }, params) {
			try {
				const response = await ReportService.fetchCampaign(params);
				return await Promise.resolve(
					resolveListParams(
						response,
						params.custom_key,
						params.custom_value,
						false,
						true,
						true
					)
				);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async createReportOnDemand({ commit }, params) {
			try {
				const response = await ReportService.createReportDemand(params);
				notificationService.notifySuccess(this, {
					to: "ReportOnDemandIndex",
				} as Notification);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async downloadReportDemand({}, params) {
			try {
				const result = await ReportService.downloadReportDemand(params);
				return await Promise.resolve({});
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async delete({}, id) {
			try {
				const response = await ReportService.deleteReport(id);
				return await Promise.resolve(true);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async deleteAll({}, param: any) {
			try {
				const response = await ReportService.deleteAll(param);
				return await Promise.resolve(true);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},
		async setActive({ commit }, params: { id: number; active: Boolean }) {
			try {
				const response = await ReportService.setActive(
					params.id,
					params.active
				);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error, { to: "" });
				return await Promise.reject(error);
			}
		},

		async getFilterSP({}, params: ReportFilterSP) {
			try {
				const response = await ReportService.getFilterSP(params);
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		},

		async getAccountSP({}) {
			try {
				const response = await ReportService.getAccountSP();
				return await Promise.resolve(response);
			} catch (error) {
				catchError(this, error);
				return await Promise.reject(error);
			}
		}
	},
};
