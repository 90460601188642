import {
	FilterProperty,
	FiltersEntity,
	ResourceDataEntity,
} from "@/interfaces/report";
import store from "@/store";

export async function parseToJson(filtersString: string) {
	try {
		return JSON.parse(filtersString);
	} catch (error) {
		return null;
	}
}

export async function getDataFromFilters(
	filtersString: string,
	property: string
) {
	const filtersObj = await parseToJson(filtersString);
	return filtersObj[property];
}

export async function getDataRangeFromFilters(filtersString: string) {
	const filtersObj = await parseToJson(filtersString);
	return filtersObj?.data_range || "";
}

export async function getFilters(
	filtersString: string,
	resources: ResourceDataEntity
): Promise<FiltersEntity> {
	const filtersObj = await parseToJson(filtersString);
	const filters: FiltersEntity = await createFiltersFromResources(
		resources,
		filtersObj
	);
	return filters;
}

export async function createFiltersFromResources(
	resources: ResourceDataEntity,
	filtersObj: any
): Promise<FiltersEntity> {

	const filters: FiltersEntity = store.state["report_v2"]["report"]["filters"];

	const payload = {
		account: {
			response: resources.account,
			filter: filtersObj.account,
		},
		campaigns: {
			response: resources.campaigns,
			filter: filtersObj.campaigns,
		},
		advertisers: {
			response: resources.advertisers,
			filter: filtersObj.advertisers,
		},
		line_items: {
			response: resources.line_items,
			filter: filtersObj.line_items,
		},
		creatives: {
			response: resources.creatives,
			filter: filtersObj.creatives,
		},
		deal_id: {
			response: resources.deal_id,
			filter: filtersObj.deal_id,
		},
		layer_name: {
			response: resources.layer_name,
			filter: filtersObj.layer_name,
		},
		ooh_layer_name: {
			response: resources.ooh_layer_name,
			filter: filtersObj.ooh_layer_name,
		},
		ooh_distance: {
			response: resources.ooh_distance,
			filter: filtersObj.ooh_distance,
		},
	};

	filters.account = filterResources(
		payload.account.response,
		payload.account.filter,
		"key",
	);

	filters.campaigns = filterResources(
		payload.campaigns.response,
		payload.campaigns.filter,
		"key",
	);

	filters.advertisers = filterResources(
		payload.advertisers.response,
		payload.advertisers.filter,
		"key",
	);

	filters.line_items = filterResources(
		payload.line_items.response,
		payload.line_items.filter,
		"key",
	);

	filters.creatives = filterResources(
		payload.creatives.response,
		payload.creatives.filter,
		"key",
	);

	filters.deal_id = filterResources(
		payload.deal_id.response,
		payload.deal_id.filter,
		"key",
	);
	filters.layer_name = filterResources(
		payload.layer_name.response,
		payload.layer_name.filter,
		"key",
	);
	filters.ooh_layer_name = filterResources(
		payload.ooh_layer_name.response,
		payload.ooh_layer_name.filter,
		"value",
	)[0];
	filters.ooh_distance = filterResources(
		payload.ooh_distance.response,
		payload.ooh_distance.filter,
		"value",
	)[0];

	return filters;
}

function filterResources(
	resourceArray: FilterProperty[],
	filterKeys: (number | string)[],
	property: "key"|"value",
): FilterProperty[] {
	const newResource: FilterProperty[] = resourceArray?.filter((resource) => {
		return filterKeys?.includes(resource[property]);
	});
	return newResource || [];
}

function filterSingleResources(
	resourceArray: FilterProperty[],
	filterKeys: (number | string)[],
	property: "key"|"value",
): FilterProperty[] {
	const newResource: FilterProperty[] = resourceArray?.filter((resource) => {
		return filterKeys?.includes(resource[property]);
	});
	return newResource || [];
}

export async function getDimensionMetricParsed(
	filtersString: string
): Promise<Record<string, number>> {
	let obj: { [key: string]: number } = {};
	try {
		const dataArray: string[] | null = await parseToJson(filtersString);

		if (dataArray === null) return obj;

		dataArray.forEach((element: string) => {
			obj[element] = 1;
		});

		return obj;
	} catch (error) {
		return obj;
	}
}

export async function converJsonToFilters(filters?: string) {
	if (!filters) {
		return {
			advertisers: [],
			campaigns: [],
			line_items: [],
			creatives: [],
			account: [],
			deal_id: [],
			layer_name: [],
			ooh_layer_name: [],
			ooh_distance: [],
		};
	}

	const toJson: Record<string, any> = JSON.parse(filters);
	const newObj: Record<string, (number | string)[]> = {};

	for (const key in toJson) {
		if (
			typeof toJson[key] === "string" &&
			toJson[key].match(/^(\d+,)*\d+$/)
		) {
			newObj[key] = toJson[key].split(",").map(Number);
		} else if (Array.isArray(toJson[key])) {
			newObj[key] = toJson[key];
		} else if (
			new Date(toJson[key]).toString() !== "Invalid Date" ||
			!isNaN(Number(toJson[key]))
		) {
			newObj[key] = toJson[key];
		}
	}

	return newObj;
}

